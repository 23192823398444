import { Flex, Form } from 'antd';
import { Color } from 'antd/es/color-picker';

import { Input } from 'components/Form';
import { colors } from 'lib/theme/colors';

import { StyledColorPicker } from './styled';

const { Item } = Form;

interface IColorPickerProps {
  colorValue: string;
  setColorValue: (value: string) => void;
  name: string;
}

const ColorPicker = ({ colorValue, setColorValue, name }: IColorPickerProps) => {
  const onColorPickerChange = (e: Color) => {
    setColorValue(e.toHexString().toUpperCase());
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.toUpperCase();
    if (value[0] !== '#') {
      value = `#${value}`;
    }
    setColorValue(value);
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const value = e.target.value;
    const validColorRegex = /^#?([A-F0-9]{6}|[A-F0-9]{3})$/;

    if (!value.match(validColorRegex)) {
      setColorValue(colors.black);
    }
  };

  return (
    <Flex gap={8}>
      <Item name={name} style={{ margin: 0 }}>
        <StyledColorPicker value={colorValue} onChange={onColorPickerChange} />
      </Item>
      <Item name={name} style={{ margin: 0 }}>
        <Input onBlur={onInputBlur} onChange={onInputChange} value={colorValue} />
      </Item>
    </Flex>
  );
};

export default ColorPicker;
