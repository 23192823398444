import { CheckCircleFilled } from '@ant-design/icons';
import { Flex } from 'antd';

import { Text } from 'components/Typography';
import { colors } from 'lib/theme/colors';

import { DotsContainer, Dot } from './styled';

interface IStepper {
  steps: string[];
  activeStep: number;
}

const DotStepper = ({ activeStep, steps }: IStepper) => {
  const renderStep = (step: number) => {
    if (step < activeStep) {
      return <CheckCircleFilled style={{ color: colors.green[100] }} />;
    }

    if (step === activeStep) {
      return <Dot key={step} color={colors.primary} />;
    }

    if (step > activeStep) {
      return <Dot key={step} color={colors.gray[24]} />;
    }

    return <Dot key={step} color={colors.primary} />;
  };

  return (
    <DotsContainer gap={8}>
      {steps.map((step, index) => (
        <Flex gap={4} align="center" key={step}>
          {renderStep(index)}
          <Text>{step}</Text>
        </Flex>
      ))}
    </DotsContainer>
  );
};

export default DotStepper;
