import { Flex, Typography } from 'antd';

import { Permission } from 'api/requests/generated/generated.schemas';
import { Business, Configurations, Geolocation, UserManagement, Account } from 'components/Icons';
import { PremiumTagIfOnePricing } from 'components/Tag/PremiumTagIfOnePricing';
import { Entitlements } from 'interfaces/enums';
import { usePrivileges, useSelectedCompany, useEntitlement } from 'lib/hooks';

import { Sidebar, Item } from './styled';
const { Text } = Typography;

const SideBar = () => {
  const { isCSA, hasSettings } = usePrivileges();
  const { permission, id } = useSelectedCompany();
  const { getEntitlement } = useEntitlement();

  const hasUnassignedConfigurations = hasSettings.modern.unassigned > 0;

  const isHasAccess = isCSA || (permission !== Permission.READ && permission !== Permission.READWRITE);

  const nonePermissions = !isCSA && permission === Permission.NONE;

  const geoEntitlements = getEntitlement(Entitlements.GEOTARGETING);

  const sidebarItems = [
    {
      title: 'Configurations',
      key: 'configurations',
      link: `/company/${id}/configurations`,
      dataTestId: 'sidebar-item:configurations',
      isShown: Boolean(id),
      icon: <Configurations />,
    },
    {
      title: 'Geolocation Rulesets',
      key: 'rulesets',
      link: `/company/${id}/geo-rulesets`,
      dataTestId: 'sidebar-item:rulesets',
      isShown: id && isHasAccess && !nonePermissions,
      icon: <Geolocation />,
      premium: geoEntitlements.showPremium,
    },
    {
      title: 'User Management',
      key: 'management',
      link: `/company/${id}/user-management`,
      dataTestId: 'sidebar-item:user-management',
      isShown: id && isHasAccess && !nonePermissions,
      icon: <UserManagement />,
    },
    {
      title: 'Company Details',
      key: 'details',
      link: `/company/${id}/details`,
      dataTestId: 'sidebar-item:company-details',
      isShown: id && !nonePermissions,
      icon: <Business />,
    },
    {
      title: 'Accounts',
      key: 'accounts',
      link: '/accounts',
      dataTestId: 'sidebar-item:accounts',
      isShown: !id && isCSA,
      icon: <Account />,
    },
    {
      title: 'Companies',
      key: 'companies',
      link: '/companies',
      dataTestId: 'sidebar-item:companies',
      isShown: !id,
      icon: <Business />,
    },
    {
      title: isCSA ? 'Configurations' : 'Unassigned',
      key: 'configurations',
      link: isCSA ? '/configurations' : '/unassigned',
      dataTestId: 'sidebar-item:configurations',
      isShown: !id && (isCSA ? true : hasUnassignedConfigurations),
      icon: <Configurations />,
    },
  ];

  return (
    <Sidebar
      width="250px"
      style={{
        height: '100%',
        position: 'sticky',
        overflow: 'auto',
        top: 80,
      }}
    >
      {sidebarItems.map(
        (sidebarItem) =>
          sidebarItem.isShown && (
            <Item to={sidebarItem.link} key={sidebarItem.key}>
              <Flex align="center" gap={14}>
                {sidebarItem.icon}
                <Text data-testid={sidebarItem.dataTestId}>{sidebarItem.title}</Text>
              </Flex>
              {sidebarItem.premium && <PremiumTagIfOnePricing size="square-small" />}
            </Item>
          ),
      )}
    </Sidebar>
  );
};

export default SideBar;
