import { AuthorizationParams } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { compressToEncodedURIComponent } from 'lz-string';

import { TrackingEvents, TrackingVariables } from 'interfaces/enums';

import { trackEvent } from './amplitude';
import { getConnection } from './auth0';
import { DMA_CONNECTIONS, TRIAL_COOKIES, ONBOARDING_TYPE, USER_AUTH0_PARAMETERS, QUERY_PARAMS } from '../consts';

export const getAuthorizationParams = (): AuthorizationParams => {
  const params: URLSearchParams = new URL(window.location.href).searchParams;
  const trial = params.get('trial');
  const onePricingPlan = params.get(QUERY_PARAMS.one_pricing_plan);
  // dma_signup remains as a fallback in case someone access an old link from email/documentation etc...
  const isDmaTrialSignup = trial === ONBOARDING_TYPE.DMA || params.get('dma_signup') === 'true';
  const isDma = isDmaTrialSignup;
  const isStandardTrialSignup = trial === ONBOARDING_TYPE.STANDARD;
  // -----------------------------------------------------------------------------------------------------------------

  const isStandard = isStandardTrialSignup;

  if (isDma) {
    Cookies.set(TRIAL_COOKIES.TRIAL_USED, ONBOARDING_TYPE.DMA);
  } else if (isStandard) {
    Cookies.set(TRIAL_COOKIES.TRIAL_USED, ONBOARDING_TYPE.STANDARD);
  }

  Object.keys(USER_AUTH0_PARAMETERS).forEach((parameterName) => {
    const urlParam = params.get(parameterName);
    if (parameterName === USER_AUTH0_PARAMETERS.uc_coupon && isStandard) {
      return;
    }
    if (urlParam) {
      Cookies.set(parameterName, urlParam);
    } else if (parameterName === USER_AUTH0_PARAMETERS.uc_lead_source) {
      const utmParams = {
        '1': {
          ad_id: '',
          adset_id: '',
          campaign_id: '',
          gclid: params.get('gclid') ?? '',
          geoloc: '',
          matchtype: '',
          msclkid: params.get('msclkid') ?? '',
          placement: '',
          utm_campaign: params.get('utm_campaign') ?? '',
          utm_content: params.get('utm_content') ?? '',
          utm_device: params.get('utm_device') ?? '',
          utm_medium: params.get('utm_medium') ?? '',
          utm_source: params.get('utm_source') ?? '',
          utm_term: params.get('utm_term') ?? '',
        },
      };
      const utmParamsEncoded = compressToEncodedURIComponent(JSON.stringify(utmParams));
      Cookies.set(parameterName, utmParamsEncoded);
    }

    if (parameterName === USER_AUTH0_PARAMETERS.uc_subscription_type && !urlParam && isDmaTrialSignup) {
      Cookies.set(parameterName, 'web');
    }
  });

  const authorisationParams: Partial<Record<USER_AUTH0_PARAMETERS, string | null>> = {
    ...(isDma
      ? { [USER_AUTH0_PARAMETERS.uc_onboarding]: ONBOARDING_TYPE.DMA }
      : isStandard
      ? { [USER_AUTH0_PARAMETERS.uc_onboarding]: ONBOARDING_TYPE.STANDARD }
      : {}),
  };

  if (onePricingPlan) {
    authorisationParams[USER_AUTH0_PARAMETERS.one_pricing_plan] = params.get(QUERY_PARAMS.one_pricing_plan);
  }

  if (params.get(USER_AUTH0_PARAMETERS.uc_subscription_type)) {
    authorisationParams[USER_AUTH0_PARAMETERS.uc_subscription_type] = params.get(
      USER_AUTH0_PARAMETERS.uc_subscription_type,
    );
  }

  // Coupons should only be applicable to DMA segment - Added condition to block usage as a failsafe
  if (params.get(USER_AUTH0_PARAMETERS.uc_coupon) && isDmaTrialSignup) {
    authorisationParams[USER_AUTH0_PARAMETERS.uc_coupon] = params.get(USER_AUTH0_PARAMETERS.uc_coupon);
  }

  /**
   * Tracking events
   */

  if (getConnection(window.location.search) !== DMA_CONNECTIONS.GOOGLE) {
    if (isDma && authorisationParams[USER_AUTH0_PARAMETERS.uc_coupon]) {
      trackEvent(TrackingEvents.CTA_FREE_TRIAL, {
        [TrackingVariables.TRIAL_TYPE]: ONBOARDING_TYPE.DMA,
      });
    } else if (!isDma && isStandard) {
      trackEvent(TrackingEvents.CTA_FREE_TRIAL, {
        [TrackingVariables.TRIAL_TYPE]: ONBOARDING_TYPE.STANDARD,
      });
    }
  }
  /**
   * =========
   */

  if (window.location.pathname.includes('login') && !isDma && !isStandard) {
    Cookies.remove(TRIAL_COOKIES.TRIAL_USED);
  }

  return {
    ...(isDma || isStandard ? authorisationParams : {}),
    audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
    redirect_uri: `${window.location.origin}/`,
    ['ext-onePricing']: Boolean(onePricingPlan),
    ['ext-isDma']: true, // always set to true to show new templates to all users
    // INFO: when url contains trial property auth0 should redirect to signup screen instead
    ...(isDmaTrialSignup || isStandardTrialSignup ? { screen_hint: 'signup' } : {}),
  };
};
