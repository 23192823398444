/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
export type RecommendedPricePlanControllerGetRecommendedPlan200AllOf = {
  body?: PricePlanRecommendationEnrichedOutDto;
};

export type RecommendedPricePlanControllerGetRecommendedPlan200 = OkResponseDto &
  RecommendedPricePlanControllerGetRecommendedPlan200AllOf;

export type RecommendedPricePlanControllerGetRecommendedPlanParams = {
  quantity: number;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type ChannelPartnerControllerGetPartnerName200AllOf = {
  body?: GetPartnerNameOutDto;
};

export type ChannelPartnerControllerGetPartnerName200 = OkResponseDto & ChannelPartnerControllerGetPartnerName200AllOf;

export type ChannelPartnerControllerGetPartnerNameParams = {
  partnerRef: string;
};

export type SubscriptionTierControllerGetSubscriptionTiers200AllOf = {
  body?: SubscriptionTiersOutDto;
};

export type SubscriptionTierControllerGetSubscriptionTiers200 = OkResponseDto &
  SubscriptionTierControllerGetSubscriptionTiers200AllOf;

export type SubscriptionTierControllerGetSubscriptionTiersParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type ConsumptionControllerGetSubscriptionConsumption200AllOf = {
  body?: ConsumptionOutDto;
};

export type ConsumptionControllerGetSubscriptionConsumption200 = OkResponseDto &
  ConsumptionControllerGetSubscriptionConsumption200AllOf;

export type ConsumptionControllerGetSubscriptionConsumptionParams = {
  periodicity: string;
  periods: number;
  dataProvider?: ConsumptionDataProviderName;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type ConsumptionControllerGetAccountConsumption200AllOf = {
  body?: ConsumptionOutDto;
};

export type ConsumptionControllerGetAccountConsumption200 = OkResponseDto &
  ConsumptionControllerGetAccountConsumption200AllOf;

export type ConsumptionControllerGetAccountConsumptionParams = {
  periodicity: string;
  periods: number;
  dataProvider?: ConsumptionDataProviderName;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type EstimationControllerEstimateUpgrade200AllOf = {
  body?: EstimationOutDto;
};

export type EstimationControllerEstimateUpgrade200 = OkResponseDto & EstimationControllerEstimateUpgrade200AllOf;

export type EstimationControllerEstimateUpgradeParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type EstimationControllerEstimateSubscription200AllOf = {
  body?: EstimationOutDto;
};

export type EstimationControllerEstimateSubscription200 = OkResponseDto &
  EstimationControllerEstimateSubscription200AllOf;

export type EstimationControllerEstimateSubscriptionParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type PaymentControllerCreatePaymentSource200AllOf = {
  body?: CreatePaymentIntentOutDto;
};

export type PaymentControllerCreatePaymentSource200 = OkResponseDto & PaymentControllerCreatePaymentSource200AllOf;

export type PaymentControllerCreatePaymentSourceParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type PaymentControllerCreatePaymentIntent200AllOf = {
  body?: CreatePaymentIntentOutDto;
};

export type PaymentControllerCreatePaymentIntent200 = OkResponseDto & PaymentControllerCreatePaymentIntent200AllOf;

export type PaymentControllerCreatePaymentIntentParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type PrivilegeControllerGetPrivileges200AllOf = {
  body?: PrivilegesOutDto;
};

export type PrivilegeControllerGetPrivileges200 = OkResponseDto & PrivilegeControllerGetPrivileges200AllOf;

export type PermissionBillingAccountControllerDelete200AllOf = {
  body?: PermissionBillingAccountOutDto;
};

export type PermissionBillingAccountControllerDelete200 = OkResponseDto &
  PermissionBillingAccountControllerDelete200AllOf;

export type PermissionBillingAccountControllerDeleteParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type PermissionBillingAccountControllerGrant200AllOf = {
  body?: PermissionBillingAccountOutDto;
};

export type PermissionBillingAccountControllerGrant200 = OkResponseDto &
  PermissionBillingAccountControllerGrant200AllOf;

export type PermissionBillingAccountControllerGrantParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type PermissionBillingAccountControllerList200AllOf = {
  body?: ListPermissionBillingAccountsOutDto[];
};

export type PermissionBillingAccountControllerList200 = OkResponseDto & PermissionBillingAccountControllerList200AllOf;

export type PermissionBillingAccountControllerListParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type AccountControllerUpgradeSubscriptionNew200AllOf = {
  body?: SubscriptionOutDto;
};

export type AccountControllerUpgradeSubscriptionNew200 = OkResponseDto &
  AccountControllerUpgradeSubscriptionNew200AllOf;

export type AccountControllerUpgradeSubscriptionNewParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type AccountControllerUpgradeSubscription200AllOf = {
  body?: SubscriptionOutDto;
};

export type AccountControllerUpgradeSubscription200 = OkResponseDto & AccountControllerUpgradeSubscription200AllOf;

export type AccountControllerUpgradeSubscriptionParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type AccountControllerGetTrials200AllOf = {
  body?: TrialOutDto;
};

export type AccountControllerGetTrials200 = OkResponseDto & AccountControllerGetTrials200AllOf;

export type AccountControllerGetTrialsParams = {
  customerId: string;
};

export type AccountControllerSearchAccounts200AllOf = {
  body?: AccountOutDto[];
};

export type AccountControllerSearchAccounts200 = OkResponseDto & AccountControllerSearchAccounts200AllOf;

export type AccountControllerSearchAccountsParams = {
  q: string;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type AccountControllerUpdateBillingAccountBillingInfo200AllOf = {
  body?: SetBillingInfoOutDto;
};

export type AccountControllerUpdateBillingAccountBillingInfo200 = OkResponseDto &
  AccountControllerUpdateBillingAccountBillingInfo200AllOf;

export type AccountControllerUpdateAccountVat200AllOf = {
  body?: VatDto;
};

export type AccountControllerUpdateAccountVat200 = OkResponseDto & AccountControllerUpdateAccountVat200AllOf;

export type AccountControllerGetAccountDetails200AllOf = {
  body?: AccountOutDto;
};

export type AccountControllerGetAccountDetails200 = OkResponseDto & AccountControllerGetAccountDetails200AllOf;

export type AccountControllerGetMyAccounts200AllOf = {
  body?: AccountOutDto[];
};

export type AccountControllerGetMyAccounts200 = OkResponseDto & AccountControllerGetMyAccounts200AllOf;

export type AccountControllerGetAccountByCustomerId200AllOf = {
  body?: SingleAccountOutDto;
};

export type AccountControllerGetAccountByCustomerId200 = OkResponseDto &
  AccountControllerGetAccountByCustomerId200AllOf;

export type AccountControllerGetAccountByCustomerIdParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerLinkageControllerUnlink200AllOf = {
  body?: UnlinkOutDto;
};

export type CustomerLinkageControllerUnlink200 = OkResponseDto & CustomerLinkageControllerUnlink200AllOf;

export type CustomerLinkageControllerUnlinkParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerLinkageControllerValidateUnlink200AllOfBody = ValidUnlinkOutDto | InvalidUnlinkOutDto;

export type CustomerLinkageControllerValidateUnlink200AllOf = {
  body?: CustomerLinkageControllerValidateUnlink200AllOfBody;
};

export type CustomerLinkageControllerValidateUnlink200 = OkResponseDto &
  CustomerLinkageControllerValidateUnlink200AllOf;

export type CustomerLinkageControllerValidateUnlinkParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerLinkageControllerLink200AllOf = {
  body?: LinkOutDto;
};

export type CustomerLinkageControllerLink200 = OkResponseDto & CustomerLinkageControllerLink200AllOf;

export type CustomerLinkageControllerLinkParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerLinkageControllerValidateLink200AllOfBody = ValidLinkOutDto | InvalidLinkOutDto;

export type CustomerLinkageControllerValidateLink200AllOf = {
  body?: CustomerLinkageControllerValidateLink200AllOfBody;
};

export type CustomerLinkageControllerValidateLink200 = OkResponseDto & CustomerLinkageControllerValidateLink200AllOf;

export type CustomerLinkageControllerValidateLinkParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type EntitlementControllerGetQuotaBySubscriptionId200AllOf = {
  body?: GetQuotaOutDto;
};

export type EntitlementControllerGetQuotaBySubscriptionId200 = OkResponseDto &
  EntitlementControllerGetQuotaBySubscriptionId200AllOf;

export type EntitlementControllerGetQuotaBySubscriptionIdParams = {
  /**
   * Comma separated quota names
   */
  filter: string;
};

export type EntitlementControllerGetEntitlementBySubscriptionId200AllOf = {
  body?: GetEntitlementOutDto;
};

export type EntitlementControllerGetEntitlementBySubscriptionId200 = OkResponseDto &
  EntitlementControllerGetEntitlementBySubscriptionId200AllOf;

export type EntitlementControllerGetEntitlementBySubscriptionIdParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type EntitlementControllerGetQuotaBySettingId200AllOf = {
  body?: GetQuotaOutDto;
};

export type EntitlementControllerGetQuotaBySettingId200 = OkResponseDto &
  EntitlementControllerGetQuotaBySettingId200AllOf;

export type EntitlementControllerGetQuotaBySettingIdParams = {
  /**
   * Comma separated quota names
   */
  filter: string;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type EntitlementControllerGetEntitlementBySettingId200AllOf = {
  body?: GetEntitlementOutDto;
};

export type EntitlementControllerGetEntitlementBySettingId200 = OkResponseDto &
  EntitlementControllerGetEntitlementBySettingId200AllOf;

export type EntitlementControllerGetEntitlementBySettingIdParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SubscriptionControllerCreateSubscriptionBasedOnPrevious200AllOfBodyItem = { [key: string]: any };

export type SubscriptionControllerCreateSubscriptionBasedOnPrevious200AllOf = {
  body?: SubscriptionControllerCreateSubscriptionBasedOnPrevious200AllOfBodyItem[];
};

export type SubscriptionControllerCreateSubscriptionBasedOnPrevious200 = OkResponseDto &
  SubscriptionControllerCreateSubscriptionBasedOnPrevious200AllOf;

export type SubscriptionControllerAttachSettingsToSubscription200AllOf = {
  body?: AttachSettingToSubscriptionOutDto[];
};

export type SubscriptionControllerAttachSettingsToSubscription200 = OkResponseDto &
  SubscriptionControllerAttachSettingsToSubscription200AllOf;

export type SubscriptionControllerAttachSettingsToSubscriptionParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SubscriptionControllerListSubscriptions200AllOf = {
  body?: SubscriptionOutDto[];
};

export type SubscriptionControllerListSubscriptions200 = OkResponseDto &
  SubscriptionControllerListSubscriptions200AllOf;

export type SubscriptionControllerListSubscriptionsParams = {
  customerId: string;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type InternalCustomerControllerListParams = {
  email: string;
};

export type CustomerAccessControllerListCustomerPermissionsByBillingAccount200AllOf = {
  body?: ListCustomerPermissionsByBillingAccountOutDto[];
};

export type CustomerAccessControllerListCustomerPermissionsByBillingAccount200 = OkResponseDto &
  CustomerAccessControllerListCustomerPermissionsByBillingAccount200AllOf;

export type CustomerAccessControllerListCustomerPermissionsByBillingAccountParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerAccessControllerDeletePermission200AllOfBody = { [key: string]: any };

export type CustomerAccessControllerDeletePermission200AllOf = {
  body?: CustomerAccessControllerDeletePermission200AllOfBody;
};

export type CustomerAccessControllerDeletePermission200 = OkResponseDto &
  CustomerAccessControllerDeletePermission200AllOf;

export type CustomerAccessControllerDeletePermissionParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerAccessControllerSetPermission200AllOfBody = { [key: string]: any };

export type CustomerAccessControllerSetPermission200AllOf = {
  body?: CustomerAccessControllerSetPermission200AllOfBody;
};

export type CustomerAccessControllerSetPermission200 = OkResponseDto & CustomerAccessControllerSetPermission200AllOf;

export type CustomerAccessControllerSetPermissionParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerAccessControllerGetPermissions200AllOf = {
  body?: GetPermissionByCustomerIdOutDto[];
};

export type CustomerAccessControllerGetPermissions200 = OkResponseDto & CustomerAccessControllerGetPermissions200AllOf;

export type CustomerAccessControllerGetPermissionsParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerControllerGetSettingsToExport200AllOf = {
  body?: GetSettingsToExportOutDto[];
};

export type CustomerControllerGetSettingsToExport200 = OkResponseDto & CustomerControllerGetSettingsToExport200AllOf;

export type CustomerControllerGetSettingsToExportParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerControllerGetBaseInfo200AllOf = {
  body?: CustomerBaseInfoOutDto;
};

export type CustomerControllerGetBaseInfo200 = OkResponseDto & CustomerControllerGetBaseInfo200AllOf;

export type CustomerControllerGetBaseInfoParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerControllerSetWhiteLabeling200AllOfBody = { [key: string]: any };

export type CustomerControllerSetWhiteLabeling200AllOf = {
  body?: CustomerControllerSetWhiteLabeling200AllOfBody;
};

export type CustomerControllerSetWhiteLabeling200 = OkResponseDto & CustomerControllerSetWhiteLabeling200AllOf;

export type CustomerControllerSetWhiteLabelingParams = {
  enable: boolean;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerControllerSetReviewMode200AllOfBody = { [key: string]: any };

export type CustomerControllerSetReviewMode200AllOf = {
  body?: CustomerControllerSetReviewMode200AllOfBody;
};

export type CustomerControllerSetReviewMode200 = OkResponseDto & CustomerControllerSetReviewMode200AllOf;

export type CustomerControllerSetReviewModeParams = {
  enable: boolean;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerControllerSetPremiumAddon200AllOfBody = { [key: string]: any };

export type CustomerControllerSetPremiumAddon200AllOf = {
  body?: CustomerControllerSetPremiumAddon200AllOfBody;
};

export type CustomerControllerSetPremiumAddon200 = OkResponseDto & CustomerControllerSetPremiumAddon200AllOf;

export type CustomerControllerSetPremiumAddonParams = {
  enable: boolean;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerControllerGetCustomerRuleSets200AllOf = {
  body?: RuleSetOutDto[];
};

export type CustomerControllerGetCustomerRuleSets200 = OkResponseDto & CustomerControllerGetCustomerRuleSets200AllOf;

export type CustomerControllerGetCustomerRuleSetsParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerControllerCreateForAccount200AllOf = {
  body?: CreateCustomerOutDto;
};

export type CustomerControllerCreateForAccount200 = OkResponseDto & CustomerControllerCreateForAccount200AllOf;

export type CustomerControllerDelete200AllOfBody = { [key: string]: any };

export type CustomerControllerDelete200AllOf = {
  body?: CustomerControllerDelete200AllOfBody;
};

export type CustomerControllerDelete200 = OkResponseDto & CustomerControllerDelete200AllOf;

export type CustomerControllerDeleteParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerControllerUpdate200AllOf = {
  body?: UpdateCustomerOutDto;
};

export type CustomerControllerUpdate200 = OkResponseDto & CustomerControllerUpdate200AllOf;

export type CustomerControllerUpdateParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerControllerGet200AllOf = {
  body?: GetCustomerByIdOutDto;
};

export type CustomerControllerGet200 = OkResponseDto & CustomerControllerGet200AllOf;

export type CustomerControllerGetParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerControllerListV2200AllOf = {
  body?: CustomerLikeWithSettingsOutDto[];
};

export type CustomerControllerListV2200 = OkResponseDto & CustomerControllerListV2200AllOf;

export type CustomerControllerListV2Params = {
  q: string;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerControllerCreate200AllOf = {
  body?: CreateCustomerOutDto;
};

export type CustomerControllerCreate200 = OkResponseDto & CustomerControllerCreate200AllOf;

export type CustomerControllerCreateParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerControllerList200AllOf = {
  body?: ListCustomerOutDto[];
};

export type CustomerControllerList200 = OkResponseDto & CustomerControllerList200AllOf;

export type CustomerControllerListParams = {
  q: string;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingAccessControllerGetListOfReviewers200AllOf = {
  body?: GetAllPermissionsOutDto[];
};

export type SettingAccessControllerGetListOfReviewers200 = OkResponseDto &
  SettingAccessControllerGetListOfReviewers200AllOf;

export type SettingAccessControllerGetListOfReviewersParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingAccessControllerRevokePermission200AllOfBody = { [key: string]: any };

export type SettingAccessControllerRevokePermission200AllOf = {
  body?: SettingAccessControllerRevokePermission200AllOfBody;
};

export type SettingAccessControllerRevokePermission200 = OkResponseDto &
  SettingAccessControllerRevokePermission200AllOf;

export type SettingAccessControllerRevokePermissionParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingAccessControllerAddPermission200AllOfBody = { [key: string]: any };

export type SettingAccessControllerAddPermission200AllOf = {
  body?: SettingAccessControllerAddPermission200AllOfBody;
};

export type SettingAccessControllerAddPermission200 = OkResponseDto & SettingAccessControllerAddPermission200AllOf;

export type SettingAccessControllerAddPermissionParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingAccessControllerGetAllPermissions200AllOf = {
  body?: GetAllPermissionsOutDto[];
};

export type SettingAccessControllerGetAllPermissions200 = OkResponseDto &
  SettingAccessControllerGetAllPermissions200AllOf;

export type SettingAccessControllerGetAllPermissionsParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerSettingControllerMoveSettingToAnotherCompany200AllOfBody = { [key: string]: any };

export type CustomerSettingControllerMoveSettingToAnotherCompany200AllOf = {
  body?: CustomerSettingControllerMoveSettingToAnotherCompany200AllOfBody;
};

export type CustomerSettingControllerMoveSettingToAnotherCompany200 = OkResponseDto &
  CustomerSettingControllerMoveSettingToAnotherCompany200AllOf;

export type CustomerSettingControllerMoveSettingToAnotherCompanyParams = {
  targetCustomerId: string;
  withPermissions: boolean;
};

export type CustomerSettingControllerDetachSettingFromCustomer200AllOfBody = { [key: string]: any };

export type CustomerSettingControllerDetachSettingFromCustomer200AllOf = {
  body?: CustomerSettingControllerDetachSettingFromCustomer200AllOfBody;
};

export type CustomerSettingControllerDetachSettingFromCustomer200 = OkResponseDto &
  CustomerSettingControllerDetachSettingFromCustomer200AllOf;

export type CustomerSettingControllerDetachSettingFromCustomerParams = {
  withPermissions: boolean;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerSettingControllerAttachExistingSettingToCustomer200AllOfBody = { [key: string]: any };

export type CustomerSettingControllerAttachExistingSettingToCustomer200AllOf = {
  body?: CustomerSettingControllerAttachExistingSettingToCustomer200AllOfBody;
};

export type CustomerSettingControllerAttachExistingSettingToCustomer200 = OkResponseDto &
  CustomerSettingControllerAttachExistingSettingToCustomer200AllOf;

export type CustomerSettingControllerAttachExistingSettingToCustomerParams = {
  copyWithPermissions: boolean;
  skipAttachToSubscription: boolean;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerSettingControllerBulkAttachSettingsToCustomer200AllOfBody = { [key: string]: any };

export type CustomerSettingControllerBulkAttachSettingsToCustomer200AllOf = {
  body?: CustomerSettingControllerBulkAttachSettingsToCustomer200AllOfBody;
};

export type CustomerSettingControllerBulkAttachSettingsToCustomer200 = OkResponseDto &
  CustomerSettingControllerBulkAttachSettingsToCustomer200AllOf;

export type CustomerSettingControllerBulkAttachSettingsToCustomerParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type CustomerSettingControllerGetAvailableTypes200AllOf = {
  body?: GetAvailableTypesOutDto;
};

export type CustomerSettingControllerGetAvailableTypes200 = OkResponseDto &
  CustomerSettingControllerGetAvailableTypes200AllOf;

export type CustomerSettingControllerGetAvailableTypesParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingControllerDuplicate200AllOf = {
  body?: DuplicateSettingOutDto;
};

export type SettingControllerDuplicate200 = OkResponseDto & SettingControllerDuplicate200AllOf;

export type SettingControllerDuplicateParams = {
  withPermissions?: boolean;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingControllerDisableReviewMode200AllOfBody = { [key: string]: any };

export type SettingControllerDisableReviewMode200AllOf = {
  body?: SettingControllerDisableReviewMode200AllOfBody;
};

export type SettingControllerDisableReviewMode200 = OkResponseDto & SettingControllerDisableReviewMode200AllOf;

export type SettingControllerDisableReviewModeParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingControllerEnableReviewMode200AllOfBody = { [key: string]: any };

export type SettingControllerEnableReviewMode200AllOf = {
  body?: SettingControllerEnableReviewMode200AllOfBody;
};

export type SettingControllerEnableReviewMode200 = OkResponseDto & SettingControllerEnableReviewMode200AllOf;

export type SettingControllerEnableReviewModeParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingControllerGetSettingsPermissionsBatch200AllOf = {
  body?: GetSettingsPermissionsBatchOutDto;
};

export type SettingControllerGetSettingsPermissionsBatch200 = OkResponseDto &
  SettingControllerGetSettingsPermissionsBatch200AllOf;

export type SettingControllerGetSettingsPermissionsBatchParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingControllerGetCustomerBySettingId200AllOf = {
  body?: SettingCustomerOutDto;
};

export type SettingControllerGetCustomerBySettingId200 = OkResponseDto &
  SettingControllerGetCustomerBySettingId200AllOf;

export type SettingControllerGetCustomerBySettingIdParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingControllerDeleteSetting200AllOfBody = { [key: string]: any };

export type SettingControllerDeleteSetting200AllOf = {
  body?: SettingControllerDeleteSetting200AllOfBody;
};

export type SettingControllerDeleteSetting200 = OkResponseDto & SettingControllerDeleteSetting200AllOf;

export type SettingControllerDeleteSettingParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingControllerGetById200AllOf = {
  body?: SettingOutDto;
};

export type SettingControllerGetById200 = OkResponseDto & SettingControllerGetById200AllOf;

export type SettingControllerGetByIdParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingControllerGetByIdWithPermissions200AllOf = {
  body?: SettingAndPermissionsOutDto;
};

export type SettingControllerGetByIdWithPermissions200 = OkResponseDto &
  SettingControllerGetByIdWithPermissions200AllOf;

export type SettingControllerGetByIdWithPermissionsParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingControllerFixInconsistent200AllOf = {
  body?: string[];
};

export type SettingControllerFixInconsistent200 = OkResponseDto & SettingControllerFixInconsistent200AllOf;

export type SettingControllerFixInconsistentParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingControllerGetInconsistent200AllOf = {
  body?: string[];
};

export type SettingControllerGetInconsistent200 = OkResponseDto & SettingControllerGetInconsistent200AllOf;

export type SettingControllerGetInconsistentParams = {
  login: string;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingControllerGetLost200AllOf = {
  body?: string[];
};

export type SettingControllerGetLost200 = OkResponseDto & SettingControllerGetLost200AllOf;

export type SettingControllerGetLostParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingControllerSearchUnassignedSettingsAll200AllOf = {
  body?: UnassignedSettingsOutDto[];
};

export type SettingControllerSearchUnassignedSettingsAll200 = OkResponseDto &
  SettingControllerSearchUnassignedSettingsAll200AllOf;

export type SettingControllerSearchUnassignedSettingsAllParams = {
  q?: string;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingControllerSearchUnassignedSettings200AllOf = {
  body?: UnassignedSettingsOutDto[];
};

export type SettingControllerSearchUnassignedSettings200 = OkResponseDto &
  SettingControllerSearchUnassignedSettings200AllOf;

export type SettingControllerSearchUnassignedSettingsParams = {
  q?: string;
};

export type SettingControllerCreateSetting200AllOf = {
  body?: CreateSettingOutDto;
};

export type SettingControllerCreateSetting200 = OkResponseDto & SettingControllerCreateSetting200AllOf;

export type SettingControllerCreateSettingParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type SettingControllerSearchSettings200AllOf = {
  body?: SearchSettingOutDto[];
};

export type SettingControllerSearchSettings200 = OkResponseDto & SettingControllerSearchSettings200AllOf;

export type SettingControllerSearchSettingsParams = {
  parentIds?: string[];
  type?: SettingModernity;
  settingIds?: string[];
  q?: string;
  forceV2?: boolean;
  aliasName?: string;
  appId?: string;
  dataController?: string;
  domain?: string;
  email?: string;
  subscriptionId?: string;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type RuleSetControllerDelete200AllOfBody = { [key: string]: any };

export type RuleSetControllerDelete200AllOf = {
  body?: RuleSetControllerDelete200AllOfBody;
};

export type RuleSetControllerDelete200 = OkResponseDto & RuleSetControllerDelete200AllOf;

export type RuleSetControllerDeleteParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type RuleSetControllerUpdate200AllOf = {
  body?: RuleSetOutDto;
};

export type RuleSetControllerUpdate200 = OkResponseDto & RuleSetControllerUpdate200AllOf;

export type RuleSetControllerUpdateParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type RuleSetControllerGetById200AllOf = {
  body?: RuleSetOutDto;
};

export type RuleSetControllerGetById200 = OkResponseDto & RuleSetControllerGetById200AllOf;

export type RuleSetControllerGetByIdParams = {
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type RuleSetControllerCreate200AllOf = {
  body?: RuleSetOutDto;
};

export type RuleSetControllerCreate200 = OkResponseDto & RuleSetControllerCreate200AllOf;

export type RuleSetControllerCreateParams = {
  customerId: string;
  /**
   * Is customer support access (CSA) enabled
   */
  customerSupportMode?: boolean;
};

export type AuthControllerLogout200AllOfBody = { [key: string]: any };

export type AuthControllerLogout200AllOf = {
  body?: AuthControllerLogout200AllOfBody;
};

export type AuthControllerLogout200 = OkResponseDto & AuthControllerLogout200AllOf;

export type AuthControllerVerifyToken200AllOf = {
  body?: VerifyTokenOutDto;
};

export type AuthControllerVerifyToken200 = OkResponseDto & AuthControllerVerifyToken200AllOf;

export type AuthControllerVerify200AllOfBody = { [key: string]: any };

export type AuthControllerVerify200AllOf = {
  body?: AuthControllerVerify200AllOfBody;
};

export type AuthControllerVerify200 = OkResponseDto & AuthControllerVerify200AllOf;

export type AuthControllerAuthenticatePartner200AllOf = {
  body?: AuthenticatePartnerOutDto;
};

export type AuthControllerAuthenticatePartner200 = OkResponseDto & AuthControllerAuthenticatePartner200AllOf;

export type AuthControllerAuthenticate200AllOfBody = { [key: string]: any };

export type AuthControllerAuthenticate200AllOf = {
  body?: AuthControllerAuthenticate200AllOfBody;
};

export type AuthControllerAuthenticate200 = OkResponseDto & AuthControllerAuthenticate200AllOf;

export interface RecommendedPricePlanBySubscriptionEnrichedOutDto {
  catalogFeatures: CatalogFeaturesOutDto;
  isCurrent: boolean;
  isPreferred: boolean;
  itemPriceId: string;
  pricePackage: string;
  pricePlan: string;
  tier: TierDto;
}

export interface PricePlanRecommendationEnrichedOutDto {
  currencyCode: string;
  quantity: number;
  recommendedPricePlans: RecommendedPricePlanBySubscriptionEnrichedOutDto[];
  subscriptionId: string;
}

export interface GetPartnerNameOutDto {
  companyName: string | null;
}

export interface GetPartnerNameInDto {
  partnerRef: string;
}

export interface CatalogOutDto {
  result: CatalogFeaturesOutDto[];
}

export type FeatureIdType = (typeof FeatureIdType)[keyof typeof FeatureIdType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeatureIdType = {
  geotargeting: 'geotargeting',
  'cross-domain-consent-sharing': 'cross-domain-consent-sharing',
  'cross-device-consent-sharing': 'cross-device-consent-sharing',
  'admin-ui-auto-translation': 'admin-ui-auto-translation',
  'admin-ui-analytics-granular': 'admin-ui-analytics-granular',
  'admin-ui-analytics-interaction': 'admin-ui-analytics-interaction',
  'admin-ui-analytics-comparison': 'admin-ui-analytics-comparison',
  'admin-ui-analytics-download': 'admin-ui-analytics-download',
  'admin-ui-bulk-editing': 'admin-ui-bulk-editing',
  'layout-first-layer': 'layout-first-layer',
  'layout-second-layer': 'layout-second-layer',
  'layout-privacy-trigger': 'layout-privacy-trigger',
  'styling-colors': 'styling-colors',
  'styling-font': 'styling-font',
  'styling-background-overlay': 'styling-background-overlay',
  'styling-background-shadow': 'styling-background-shadow',
  'styling-layer-corners': 'styling-layer-corners',
  'styling-logo': 'styling-logo',
  'styling-privacy-button': 'styling-privacy-button',
  'styling-custom-css': 'styling-custom-css',
  'dps-format': 'dps-format',
  'scanner-frequency': 'scanner-frequency',
  'scanner-auto-population': 'scanner-auto-population',
  'auto-blocking': 'auto-blocking',
  'selected-app-version': 'selected-app-version',
  'unique-domains': 'unique-domains',
  'number-of-configs': 'number-of-configs',
  'cmp-languages-per-config': 'cmp-languages-per-config',
  'framework-tcf': 'framework-tcf',
} as const;

export interface EntitlementOutDto {
  featureId: FeatureIdType;
  value: string;
}

export interface CatalogFeaturesOutDto {
  entitlements: EntitlementOutDto[];
  externalName: string;
  id: string;
  sortOrder: number;
}

export interface GetQuotaQueryDto {
  /** Comma separated quota names */
  filter: string;
}

export interface SingleQuotaDto {
  inUse: number;
  limit: number | null;
}

export interface UniqueDomainsDto {
  inUse: number;
  limit: number | null;
  uniqueDomains: string[];
}

export interface QuotaDto {
  'cmp-languages-per-config'?: SingleQuotaDto;
  'framework-tcf'?: SingleQuotaDto;
  'number-of-configs'?: SingleQuotaDto;
  'unique-domains'?: UniqueDomainsDto;
}

export interface GetQuotaOutDto {
  externalName: string;
  internalPlanId: string;
  quota: QuotaDto;
}

export interface GetEntitlementOutDto {
  entitlement: EntitlementDto;
  externalName: string;
  internalPlanId: string;
}

export type SelectedAppVersion = (typeof SelectedAppVersion)[keyof typeof SelectedAppVersion];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectedAppVersion = {
  v2: 'v2',
  v3: 'v3',
} as const;

export type ScannerFrequency = (typeof ScannerFrequency)[keyof typeof ScannerFrequency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScannerFrequency = {
  'on-demand': 'on-demand',
  monthly: 'monthly',
  weekly: 'weekly',
} as const;

export type DpsFormat = (typeof DpsFormat)[keyof typeof DpsFormat];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DpsFormat = {
  short: 'short',
  custom: 'custom',
} as const;

export type StylingPrivacyButton = (typeof StylingPrivacyButton)[keyof typeof StylingPrivacyButton];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StylingPrivacyButton = {
  default: 'default',
  custom: 'custom',
} as const;

export type StylingLogo = (typeof StylingLogo)[keyof typeof StylingLogo];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StylingLogo = {
  default: 'default',
  custom: 'custom',
} as const;

export type StylingLayerCorners = (typeof StylingLayerCorners)[keyof typeof StylingLayerCorners];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StylingLayerCorners = {
  default: 'default',
  custom: 'custom',
} as const;

export type StylingBackgroundShadow = (typeof StylingBackgroundShadow)[keyof typeof StylingBackgroundShadow];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StylingBackgroundShadow = {
  off: 'off',
  custom: 'custom',
} as const;

export type StylingBackgroundOverlay = (typeof StylingBackgroundOverlay)[keyof typeof StylingBackgroundOverlay];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StylingBackgroundOverlay = {
  off: 'off',
  custom: 'custom',
} as const;

export type StylingFont = (typeof StylingFont)[keyof typeof StylingFont];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StylingFont = {
  default: 'default',
  custom: 'custom',
} as const;

export type StylingColors = (typeof StylingColors)[keyof typeof StylingColors];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StylingColors = {
  default: 'default',
  custom: 'custom',
} as const;

export type LayoutPrivacyTrigger = (typeof LayoutPrivacyTrigger)[keyof typeof LayoutPrivacyTrigger];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LayoutPrivacyTrigger = {
  button: 'button',
  custom: 'custom',
} as const;

export type LayoutSecondLayer = (typeof LayoutSecondLayer)[keyof typeof LayoutSecondLayer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LayoutSecondLayer = {
  center: 'center',
  custom: 'custom',
} as const;

export type LayoutFirstLayer = (typeof LayoutFirstLayer)[keyof typeof LayoutFirstLayer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LayoutFirstLayer = {
  'privacy-wall': 'privacy-wall',
  custom: 'custom',
} as const;

export type AdminUIAnalyticsInteraction =
  (typeof AdminUIAnalyticsInteraction)[keyof typeof AdminUIAnalyticsInteraction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminUIAnalyticsInteraction = {
  disabled: 'disabled',
  overview: 'overview',
  detailed: 'detailed',
} as const;

export type AdminUIAnalyticsGranular = (typeof AdminUIAnalyticsGranular)[keyof typeof AdminUIAnalyticsGranular];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminUIAnalyticsGranular = {
  overview: 'overview',
  detailed: 'detailed',
} as const;

export interface EntitlementDto {
  'admin-ui-analytics-comparison': boolean;
  'admin-ui-analytics-download': boolean;
  'admin-ui-analytics-granular': AdminUIAnalyticsGranular;
  'admin-ui-analytics-interaction': AdminUIAnalyticsInteraction;
  'admin-ui-auto-translation': boolean;
  'admin-ui-bulk-editing': boolean;
  'auto-blocking': boolean;
  'cross-device-consent-sharing': boolean;
  'cross-domain-consent-sharing': boolean;
  'dps-format': DpsFormat;
  geotargeting: boolean;
  'layout-first-layer': LayoutFirstLayer;
  'layout-privacy-trigger': LayoutPrivacyTrigger;
  'layout-second-layer': LayoutSecondLayer;
  'scanner-auto-population': boolean;
  'scanner-frequency': ScannerFrequency;
  'selected-app-version': SelectedAppVersion;
  'styling-background-overlay': StylingBackgroundOverlay;
  'styling-background-shadow': StylingBackgroundShadow;
  'styling-colors': StylingColors;
  'styling-custom-css': boolean;
  'styling-font': StylingFont;
  'styling-layer-corners': StylingLayerCorners;
  'styling-logo': StylingLogo;
  'styling-privacy-button': StylingPrivacyButton;
}

export type ListPermissionBillingAccountsOutDtoPermission =
  (typeof ListPermissionBillingAccountsOutDtoPermission)[keyof typeof ListPermissionBillingAccountsOutDtoPermission];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListPermissionBillingAccountsOutDtoPermission = {
  NONE: 'NONE',
  LEGACY_ADMIN: 'LEGACY_ADMIN',
  READ: 'READ',
  READWRITE: 'READWRITE',
  REVIEW: 'REVIEW',
  ADMIN: 'ADMIN',
  SUPERADMIN_READ: 'SUPERADMIN_READ',
  SUPERADMIN_READWRITE: 'SUPERADMIN_READWRITE',
  SUPPORT: 'SUPPORT',
  SUPPORT_USER: 'SUPPORT_USER',
} as const;

export interface ListPermissionBillingAccountsOutDto {
  billingAccountId: string;
  id: number;
  isOwner: boolean;
  login: string;
  notify?: boolean;
  permission: ListPermissionBillingAccountsOutDtoPermission;
}

export type ConsumptionOutDtoConsumption = { [key: string]: SubscriptionConsumptionDto };

export interface ConsumptionOutDto {
  consumption: ConsumptionOutDtoConsumption;
}

export interface SubscriptionMetricsDto {
  estimatedConsumption: number;
  unit: string;
}

export interface TierOutDto {
  maxValue: number;
  minValue: number;
  monthlyPrice: number;
  price: number;
}

export interface SubscriptionTiersOutDto {
  currencyCode: string;
  id: string;
  quantity: number;
  tiers: TierOutDto[];
}

export interface SubscriptionConsumptionDto {
  breakdown: SubscriptionConsumptionDtoBreakdown;
  metrics: SubscriptionMetricsDto;
  overuse: CheckOveruseOutDto;
  recommendedTier?: TierOutDto;
}

export type MonthYearConsumptionDtoBySettings = { [key: string]: number };

export interface MonthYearConsumptionDto {
  bySettings: MonthYearConsumptionDtoBySettings;
  total: number;
}

export type SubscriptionConsumptionDtoBreakdown = { [key: string]: MonthYearConsumptionDto };

export interface CheckOveruseRule {
  name: string;
}

export interface CheckOveruseMetrics {
  allOveruseSeries: string[][];
  currentSeries: string[] | null;
  overusePeriods: string[];
}

export interface CheckOveruseDetails {
  metrics: CheckOveruseMetrics;
  rule: CheckOveruseRule;
}

export interface CheckOveruseOutDto {
  details: CheckOveruseDetails;
  shouldNotifyAboutOveruse: boolean;
}

export interface EstimationMetricOutDto {
  taxableAmount: number;
  total: number;
  totalWithoutDiscount: number;
}

export type BillingCycle = (typeof BillingCycle)[keyof typeof BillingCycle];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingCycle = {
  Yearly: 'Yearly',
  Monthly: 'Monthly',
  'Every-6-months': 'Every-6-months',
} as const;

export type BillingPeriodUnit = (typeof BillingPeriodUnit)[keyof typeof BillingPeriodUnit];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingPeriodUnit = {
  year: 'year',
  month: 'month',
} as const;

export interface DiscountDto {
  amount: number;
  discountPercentage: number;
  discountType: string;
}

export interface EstimationOutDto {
  billingCycle: BillingCycle;
  billingEmail: string;
  billingPeriod: number;
  billingPeriodUnit: BillingPeriodUnit;
  currencyCode: string;
  discount: DiscountDto;
  package: string;
  perBillingCycle: EstimationMetricOutDto;
  perMonth: EstimationMetricOutDto;
  perYear: EstimationMetricOutDto;
  tier: TierDto;
}

export interface CreatePaymentSourceByPaymentIntentOutDto {
  [key: string]: any;
}

export interface CreatePaymentIntentOutDto {
  amount: number;
  createdAt: number;
  currencyCode: string;
  expiresAt: number;
  gateway: string;
  gatewayAccountId: string;
  id: string;
  modifiedAt: number;
  paymentMethodType: string;
  status: string;
}

export interface TierDto {
  maxValue: number | null;
  minValue: number;
  monthlyPrice: number;
  price: number;
}

export interface RecommendedPricePlanBySubscriptionOutDto {
  isCurrent: boolean;
  isPreferred: boolean;
  itemPriceId: string;
  pricePackage: string;
  pricePlan: string;
  tier: TierDto;
}

export interface PricePlanRecommendationOutDto {
  currencyCode: string;
  quantity: number;
  recommendedPricePlans: RecommendedPricePlanBySubscriptionOutDto[];
  subscriptionId: string;
}

export interface ValidLinkBillingOutDto {
  customer: CustomerInfoOutDto;
  isValid: boolean;
}

export interface UnlinkBillingOutDto {
  finalizedSettingSubscriptions: SettingSubscriptionOutDto[];
}

export interface UnlinkBillingInDto {
  billingCustomerId: string;
  billingEmail: string;
  chargebeeCustomerId: string;
  permissionCustomerId: string;
  settingIds: string[];
}

export interface InvalidLinkBillingOutDto {
  isValid: boolean;
  reason: string;
}

export interface DetachSettingFromSubscriptionOutDto {
  finishedAt: string;
  settingId: string;
  startedAt: string;
  subscriptionId: string;
}

export interface AttachSettingToSubscriptionOutDto {
  finishedAt: string;
  settingId: string;
  startedAt: string;
  subscriptionId: string;
}

export interface VatDto {
  vatNumber: string;
  vatNumberStatus: string;
  vatNumberValidatedTime: number;
}

export interface TrialSubscriptionOutDto {
  couponId?: string;
  id: string;
  package: string;
  pricePlan: string;
  trialFinishedAt: string | null;
  type: SubscriptionType;
}

export interface TrialOutDto {
  shouldShowLockoutScreen: boolean;
  shouldShowTrialBar: boolean;
  subscriptionsInGracePeriod: TrialSubscriptionOutDto[];
  subscriptionsInTrial: TrialSubscriptionOutDto[];
}

export interface SingleAccountOutDto {
  chargebeeCustomerId: string;
  id: string;
  name: string;
  user: UserDto;
}

export interface SetBillingInfoOutDto {
  city?: string;
  country?: string;
  name?: string;
  street?: string;
  vatNumber?: string;
  zip?: string;
}

export type AccountHolderType = (typeof AccountHolderType)[keyof typeof AccountHolderType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountHolderType = {
  individual: 'individual',
  company: 'company',
} as const;

export interface BankAccountDto {
  accountHolderType: AccountHolderType;
  bankName: string;
  firstName: string;
  id: string;
  last4: string;
  lastName: string;
  nameOnAccount: string;
}

export interface CardDto {
  brand: string;
  expiryMonth: number;
  expiryYear: number;
  id: string;
  last4: string;
}

export type PaymentSourceStatus = (typeof PaymentSourceStatus)[keyof typeof PaymentSourceStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSourceStatus = {
  valid: 'valid',
  expiring: 'expiring',
  expired: 'expired',
  invalid: 'invalid',
  pending_verification: 'pending_verification',
} as const;

export type PaymentSourceType = (typeof PaymentSourceType)[keyof typeof PaymentSourceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSourceType = {
  card: 'card',
  direct_debit: 'direct_debit',
} as const;

export interface PaymentSourceDto {
  bankAccount: BankAccountDto;
  card: CardDto;
  id: string;
  status: PaymentSourceStatus;
  type: PaymentSourceType;
}

export interface UserDto {
  email: string;
}

export interface CompanySettingDto {
  aliasName: string;
  dataController: string;
  id: string;
}

export interface CompanyDto {
  id: string;
  name: string;
  settings: CompanySettingDto[];
}

export type SubscriptionDtoShowUpgradeButton = { [key: string]: any };

export interface SubscriptionDto {
  actualSettingsCount: number;
  couponId: string;
  currencyCode: string;
  finishedAt: string | null;
  id: string;
  initialTrialFinishedAt: string | null;
  maxValue: number;
  monthlyPrice: number;
  nextBillingAt: string | null;
  package: string;
  price: number;
  pricePlan: string;
  quantity: number;
  settingSubscriptions: SettingSubscriptionDto[];
  showUpgradeButton?: SubscriptionDtoShowUpgradeButton;
  startedAt: string;
  status: SubscriptionStatus;
  trialFinishedAt: string | null;
  trialStartedAt: string | null;
  type: SubscriptionType;
}

export interface AccountOutDto {
  chargebeeCustomerId: string;
  city: string;
  companies: CompanyDto[];
  country: string;
  id: string;
  name: string;
  paymentSources: PaymentSourceDto[];
  permission: Permission;
  postcode: string;
  primaryPaymentSourceId: string;
  street: string;
  subscriptions: SubscriptionDto[];
  user: UserDto;
  vatNumber?: string | null;
}

export interface PrivilegesOutDto {
  hasAccounts: boolean;
  hasCompanies: CustomersAmountOutDto;
  hasSettings: SettingsAmountOutDto;
  isSupport: boolean;
}

export type SettingCustomerOutDtoPermission =
  (typeof SettingCustomerOutDtoPermission)[keyof typeof SettingCustomerOutDtoPermission];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SettingCustomerOutDtoPermission = {
  NONE: 'NONE',
  LEGACY_ADMIN: 'LEGACY_ADMIN',
  READ: 'READ',
  READWRITE: 'READWRITE',
  REVIEW: 'REVIEW',
  ADMIN: 'ADMIN',
  SUPERADMIN_READ: 'SUPERADMIN_READ',
  SUPERADMIN_READWRITE: 'SUPERADMIN_READWRITE',
  SUPPORT: 'SUPPORT',
  SUPPORT_USER: 'SUPPORT_USER',
} as const;

export interface SettingCustomerOutDto {
  billingCustomerId: string;
  companyId: string;
  companyName: string;
  hasWhiteLabeling: boolean;
  inherited: boolean;
  permission: SettingCustomerOutDtoPermission;
  settingId: string;
}

export interface PermissionDto {
  login: string;
  permission: Permission;
}

export type SettingAndPermissionsOutDtoSetting = {
  id?: string;
  legacyPermissions?: PermissionDto[];
  modernPermissions?: PermissionDto[];
};

export type SettingAndPermissionsOutDtoCustomer = {
  id?: string;
  modernPermissions?: PermissionDto[];
};

export interface SettingAndPermissionsOutDto {
  customer: SettingAndPermissionsOutDtoCustomer;
  setting: SettingAndPermissionsOutDtoSetting;
}

export interface ModernSettingsAmountOutDto {
  assigned: number;
  unassigned: number;
}

export interface SettingsAmountOutDto {
  legacy: number;
  modern: ModernSettingsAmountOutDto;
}

export type SettingsAppVersion = (typeof SettingsAppVersion)[keyof typeof SettingsAppVersion];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SettingsAppVersion = {
  V1: 'V1',
  V2: 'V2',
  V3: 'V3',
} as const;

export interface UnassignedSettingsOutDto {
  aliasName: string;
  appIds: string[];
  dataController: string;
  domains: string[];
  id: string;
  legacyAdmins: string[];
  legacyFeatures: LegacyFeaturesDto;
  legalFramework: LegalFramework;
  permission: Permission;
  selectedSettingsAppVersion: SettingsAppVersion;
  tcf2Enabled: boolean;
}

export interface SearchSettingOutDto {
  aliasName: string;
  appIds: string[];
  dataController: string;
  domains: string[];
  id: string;
  inherited: boolean;
  legacyAdmins: string[];
  legacyFeatures: LegacyFeaturesDto;
  legalFramework: LegalFramework;
  parentId: string;
  parentName: string;
  parentPermission: Permission;
  permission: Permission;
  regions: string[];
  reviewMode: boolean;
  ruleSets: SettingRuleSetOutDto[];
  selectedSettingsAppVersion: SettingsAppVersion;
  subscription: ExtendedSubscriptionOutDto;
  tcf2Enabled: boolean;
}

export type ExtendedSubscriptionOutDtoShowUpgradeButton = { [key: string]: any };

export type SettingSubscriptionAssignment =
  (typeof SettingSubscriptionAssignment)[keyof typeof SettingSubscriptionAssignment];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SettingSubscriptionAssignment = {
  past: 'past',
  current: 'current',
  future: 'future',
} as const;

export interface SettingSubscriptionDto {
  finishedAt: string;
  settingId: string;
  startedAt: string;
}

export type SubscriptionStatus = (typeof SubscriptionStatus)[keyof typeof SubscriptionStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionStatus = {
  cancelled: 'cancelled',
  active: 'active',
  future: 'future',
  in_trial: 'in_trial',
  non_renewing: 'non_renewing',
} as const;

export type SubscriptionType = (typeof SubscriptionType)[keyof typeof SubscriptionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionType = {
  web: 'web',
  app: 'app',
} as const;

export interface ExtendedSubscriptionOutDto {
  actualSettingsCount: number;
  assignment: SettingSubscriptionAssignment;
  couponId: string;
  currencyCode: string;
  finishedAt: string | null;
  id: string;
  initialTrialFinishedAt: string | null;
  maxValue: number;
  monthlyPrice: number;
  nextBillingAt: string | null;
  package: string;
  price: number;
  pricePlan: string;
  quantity: number;
  settingSubscriptions: SettingSubscriptionDto[];
  showUpgradeButton?: ExtendedSubscriptionOutDtoShowUpgradeButton;
  startedAt: string;
  status: SubscriptionStatus;
  trialFinishedAt: string | null;
  trialStartedAt: string | null;
  type: SubscriptionType;
}

export interface GetAvailableTypesOutDto {
  isAppAvailable: boolean;
  isWebAvailable: boolean;
}

export interface SearchUnassignedSettingsDto {
  q?: string;
}

export interface SettingRuleSetOutDto {
  id: string;
  name: string;
}

export interface SearchSettingsQuery {
  aliasName?: string;
  appId?: string;
  dataController?: string;
  domain?: string;
  email?: string;
  forceV2?: boolean;
  parentIds?: string[];
  q?: string;
  settingIds?: string[];
  subscriptionId?: string;
  type?: SettingModernity;
}

export interface LegacyFeaturesDto {
  duplicateSetting: boolean;
}

export interface SettingOutDto {
  aliasName: string;
  appIds: string[];
  dataController: string;
  domains: string[];
  id: string;
  inherited: boolean;
  legacyAdmins: string[];
  legacyFeatures: LegacyFeaturesDto;
  parentId: string;
  parentName: string;
  permission: Permission;
}

export interface GetAllPermissionsOutDto {
  id: string;
  inherited: boolean;
  login: string;
  parentId?: string;
  permission: Permission;
}

export interface DuplicateSettingQueryDto {
  withPermissions?: boolean;
}

export interface DuplicateSettingOutDto {
  settingsId: string;
}

export interface CreateSettingOutDto {
  settingId: string;
}

export interface InvalidUnlinkOutDto {
  isValid: boolean;
  reason: string;
}

export interface SettingSubscriptionOutDto {
  createdAt?: string;
  finishedAt: string | null;
  id: string;
  settingId: string;
  startedAt: string | null;
  subscriptionId: string;
  updatedAt?: string;
}

export interface ValidUnlinkOutDto {
  settingSubscriptions: SettingSubscriptionOutDto[];
}

export interface UnlinkOutDto {
  customer: BaseCustomerOutDto;
  finalizedSettingSubscriptions: SettingSubscriptionOutDto[];
}

export interface InvalidLinkOutDto {
  isValid: boolean;
  reason: string;
}

export interface CustomerInfoOutDto {
  address: string;
  billingCustomerId: string;
  billingEmail: string;
  city: string;
  country: string;
  name: string;
  postcode: string;
}

export interface ValidLinkOutDto {
  customer: CustomerInfoOutDto;
  isValid: boolean;
}

export interface LinkOutDto {
  chargebeeCustomerId: string;
  createdAt: string;
  id: string;
  name: string;
  permissionCustomerId: string;
  resourceVersion: number;
  updatedAt: string;
}

export interface ListCustomerPermissionsByBillingAccountOutDto {
  customerId: string;
  permission: Permission;
}

export interface GetSettingsToExportOutDto {
  createdAt?: string;
  settingsId: string;
}

export interface GetPermissionByCustomerIdOutDto {
  id: string;
  login: string;
  permission: Permission;
}

export interface RuleSetOutDto {
  defaultRule: DefaultRule;
  description: string;
  isDeleted: boolean;
  name: string;
  rules: RuleDto[];
  ruleSetId: string;
}

export interface GetCustomerByIdOutDto {
  address?: string;
  billingCustomerId?: string;
  billingEmail?: string;
  city?: string;
  country?: string;
  hasPremiumAddon?: boolean;
  hasWhiteLabeling?: boolean;
  id: string;
  name: string;
  permission?: Permission;
  postcode?: string;
  reviewMode?: boolean;
  ruleSets: RuleSetOutDto[];
  vat?: string;
}

export interface CustomersAmountOutDto {
  direct: number;
  indirect: number;
}

export interface SubscriptionOutDto {
  [key: string]: any;
}

export interface CustomerStatusOutDto {
  hasPaymentSource: boolean;
  shouldShowLockoutScreen: boolean;
  shouldShowTrialBar: boolean;
  subscriptionsInGracePeriod: SubscriptionOutDto[];
  subscriptionsInTrial: SubscriptionOutDto[];
}

export interface SettingLikeOutDto {
  aliasName: string;
  reviewMode: boolean;
  settingId: string;
  settingPermission: Permission;
}

export interface CustomerLikeWithSettingsOutDto {
  customerPermission: Permission;
  id: string;
  name: string;
  reviewMode: boolean;
  settings: SettingLikeOutDto[];
  settingsAmount: number;
}

export interface CustomerBaseInfoOutDto {
  billingCustomerId: string;
  canSelectLegalFramework: boolean;
  name: string;
  permission: Permission;
  reviewMode: boolean;
}

export interface TokenDto {
  token: string;
}

export interface AuthenticatePartnerOutDto {
  accessToken: string;
}

export interface ValidationError {
  message: string;
}

export interface Payload {
  /** user email or partner user id */
  user: string;
}

export interface VerifyTokenOutDto {
  isValid: boolean;
  /** Payload of the token, undefined if the token is not valid */
  payload?: Payload;
  /** Validation error, undefined if the token is valid */
  validationError?: ValidationError;
}

export type FailResponseDtoBody = { [key: string]: any };

export interface ErrorDetailsDto {
  error: string;
  http_code: number;
  msg: string;
}

export interface FailResponseDto {
  body: FailResponseDtoBody;
  error: ErrorDetailsDto;
  success: boolean;
}

export type OkResponseDtoBody = { [key: string]: any };

export interface OkResponseDto {
  body: OkResponseDtoBody;
  success: boolean;
}

export interface UpdateBillingAccountInDto {
  ownerEmail: string;
}

export interface BaseCustomerOutDto {
  address?: string;
  billingCustomerId?: string;
  billingEmail?: string;
  city?: string;
  country?: string;
  hasPremiumAddon?: boolean;
  hasWhiteLabeling?: boolean;
  id: string;
  name: string;
  permission?: Permission;
  postcode?: string;
  reviewMode?: boolean;
  vat?: string;
}

export type PermissionBillingAccountOutDtoPermission =
  (typeof PermissionBillingAccountOutDtoPermission)[keyof typeof PermissionBillingAccountOutDtoPermission];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionBillingAccountOutDtoPermission = {
  NONE: 'NONE',
  LEGACY_ADMIN: 'LEGACY_ADMIN',
  READ: 'READ',
  READWRITE: 'READWRITE',
  REVIEW: 'REVIEW',
  ADMIN: 'ADMIN',
  SUPERADMIN_READ: 'SUPERADMIN_READ',
  SUPERADMIN_READWRITE: 'SUPERADMIN_READWRITE',
  SUPPORT: 'SUPPORT',
  SUPPORT_USER: 'SUPPORT_USER',
} as const;

export interface PermissionBillingAccountOutDto {
  billingAccountId: string;
  id: number;
  login: string;
  notify?: boolean;
  permission: PermissionBillingAccountOutDtoPermission;
}

export interface BillingAccountOutDto {
  id: string;
  owner: string;
}

export interface UpdateBillingAccountOutDto {
  billingAccount: BillingAccountOutDto;
  permissionBillingAccount: PermissionBillingAccountOutDto;
}

export interface CreateBillingAccountOutDto {
  billingAccount: BillingAccountOutDto;
  company: BaseCustomerOutDto;
  permissionBillingAccount: PermissionBillingAccountOutDto;
}

export interface CreateCompanyInDto {
  address?: string;
  city?: string;
  country?: string;
  name: string;
  postcode?: string;
  vat?: string;
}

export type CreateBillingAccountInDtoCompany = CreateCompanyInDto | null;

export interface CreateBillingAccountInDto {
  company: CreateBillingAccountInDtoCompany;
  id: string;
  ownerEmail: string;
}

export type CreateSettingAndAttachToCompanyInDtoType =
  (typeof CreateSettingAndAttachToCompanyInDtoType)[keyof typeof CreateSettingAndAttachToCompanyInDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateSettingAndAttachToCompanyInDtoType = {
  WEB: 'WEB',
  APP: 'APP',
} as const;

export type CreateSettingAndAttachToCompanyInDtoCreateSettingInput = { [key: string]: any };

export interface CreateSettingAndAttachToCompanyInDto {
  companyId: string;
  createSettingInput: CreateSettingAndAttachToCompanyInDtoCreateSettingInput;
  type: CreateSettingAndAttachToCompanyInDtoType;
}

export type ConsumptionDataProviderName =
  (typeof ConsumptionDataProviderName)[keyof typeof ConsumptionDataProviderName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsumptionDataProviderName = {
  bigQuery: 'bigQuery',
  redis: 'redis',
  redisAndBigQuery: 'redisAndBigQuery',
} as const;

export interface GracePeriodExpirationOutDto {
  companyIds: string[];
  dryRun: boolean;
  settingIds: string[];
}

export interface GracePeriodExpirationInDto {
  billingCustomerIds: string[];
  settingIds: string[];
}

export interface EstimateUpgradeInDto {
  pricePlan: SubscriptionPricePlan;
  quantity: number;
}

export interface CreatePaymentSourceByPaymentIntentInDto {
  paymentIntentId: string;
}

export interface CreatePaymentIntentInDto {
  /** Amount in cents */
  amount: number;
  currencyCode: string;
}

export interface RevokePermissionBillingAccountInDto {
  login: string;
}

export type PermissionBillingAccountInDtoPermission =
  (typeof PermissionBillingAccountInDtoPermission)[keyof typeof PermissionBillingAccountInDtoPermission];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionBillingAccountInDtoPermission = {
  NONE: 'NONE',
  LEGACY_ADMIN: 'LEGACY_ADMIN',
  READ: 'READ',
  READWRITE: 'READWRITE',
  REVIEW: 'REVIEW',
  ADMIN: 'ADMIN',
  SUPERADMIN_READ: 'SUPERADMIN_READ',
  SUPERADMIN_READWRITE: 'SUPERADMIN_READWRITE',
  SUPPORT: 'SUPPORT',
  SUPPORT_USER: 'SUPPORT_USER',
} as const;

export interface PermissionBillingAccountInDto {
  login: string;
  notify?: boolean;
  permission: PermissionBillingAccountInDtoPermission;
}

export type SubscriptionPricePlan = (typeof SubscriptionPricePlan)[keyof typeof SubscriptionPricePlan];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionPricePlan = {
  Advanced: 'Advanced',
  Premium: 'Premium',
  OneClick: 'OneClick',
  Summer2024: 'Summer2024',
  FreeSimplified: 'FreeSimplified',
  EssentialSimplified: 'EssentialSimplified',
  ProSimplified: 'ProSimplified',
  BusinessSimplified: 'BusinessSimplified',
  FreeExtended: 'FreeExtended',
  EssentialExtended: 'EssentialExtended',
  PlusExtended: 'PlusExtended',
  ProExtended: 'ProExtended',
  BusinessExtended: 'BusinessExtended',
} as const;

export interface UpgradeSubscriptionNewInDto {
  paymentIntentId?: string;
  pricePlan: SubscriptionPricePlan;
  quantity: number;
}

export interface UpgradeSubscriptionInDto {
  newQuantity: number;
}

export interface SetBillingInfoInDto {
  city?: string;
  country?: string;
  name?: string;
  street?: string;
  vatNumber?: string;
  zip?: string;
}

export interface AccountInDto {
  vatNumber: string;
}

export interface UnlinkInDto {
  billingEmail: string;
  chargebeeCustomerId: string;
  permissionCustomerId: string;
}

export interface LinkInDto {
  billingEmail: string;
  chargebeeCustomerId: string;
  permissionCustomerId: string;
}

export interface HealthOutDto {
  up: boolean;
}

export type ExtendedSubscriptionStatus = (typeof ExtendedSubscriptionStatus)[keyof typeof ExtendedSubscriptionStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExtendedSubscriptionStatus = {
  cancelled: 'cancelled',
  active: 'active',
  future: 'future',
  in_trial: 'in_trial',
  non_renewing: 'non_renewing',
  grace_period: 'grace_period',
  no_subscription: 'no_subscription',
  unknown: 'unknown',
} as const;

export interface GetCurrentSubscriptionStatusOutDto {
  status: ExtendedSubscriptionStatus;
}

export interface CreateSubscriptionBasedOnPreviousInDto {
  gatewayToken: string;
}

export interface AttachSettingsToSubscriptionInDto {
  settingIds: string[];
}

export interface ListCustomerOutDto {
  address: string;
  billingCustomerId: string;
  city: string;
  country: string;
  id: string;
  name: string;
  permission: Permission;
  postcode: string;
}

export interface UpdateCustomerOutDto {
  address?: string;
  billingCustomerId?: string;
  billingEmail?: string;
  city?: string;
  country?: string;
  hasPremiumAddon?: boolean;
  hasWhiteLabeling?: boolean;
  id: string;
  name: string;
  permission?: Permission;
  postcode?: string;
  reviewMode?: boolean;
  vat?: string;
}

export interface PatchCustomerInDto {
  billingCustomerId?: string | null;
}

export interface CreateCustomerOutDto {
  address?: string;
  billingCustomerId?: string;
  billingEmail?: string;
  city?: string;
  country?: string;
  hasPremiumAddon?: boolean;
  hasWhiteLabeling?: boolean;
  id: string;
  name: string;
  permission?: Permission;
  postcode?: string;
  reviewMode?: boolean;
  vat?: string;
}

export interface DeletePermissionInDto {
  login: string;
}

export interface SetPermissionInDto {
  login: string;
  notify: boolean;
  permission: Permission;
}

export interface SetPermissionBodyDto {
  permission: SetPermissionInDto;
}

export interface CreateCustomerForAccountInDto {
  address?: string;
  city?: string;
  country?: string;
  name: string;
  postcode?: string;
}

export interface BaseCustomerInDto {
  address?: string;
  billingCustomerId?: string;
  billingEmail?: string;
  city?: string;
  country?: string;
  hasPremiumAddon?: boolean;
  hasWhiteLabeling?: boolean;
  name: string;
  postcode?: string;
  reviewMode?: boolean;
  vat?: string;
}

export interface UpdateCustomerInDto {
  customer: BaseCustomerInDto;
}

export interface CreateCustomerInDto {
  customer: BaseCustomerInDto;
}

export interface SettingPermission {
  inherited: boolean;
  permission: string;
  settingId: string;
}

export interface GetSettingsPermissionsBatchOutDto {
  login: string;
  permissions: SettingPermission[];
}

export interface GetSettingsPermissionsBatchInDto {
  login: string;
  settingIds: string[];
}

export interface RevokePermissionInDto {
  login: string;
}

export type Permission = (typeof Permission)[keyof typeof Permission];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Permission = {
  NONE: 'NONE',
  LEGACY_ADMIN: 'LEGACY_ADMIN',
  READ: 'READ',
  READWRITE: 'READWRITE',
  REVIEW: 'REVIEW',
  ADMIN: 'ADMIN',
  SUPERADMIN_READ: 'SUPERADMIN_READ',
  SUPERADMIN_READWRITE: 'SUPERADMIN_READWRITE',
  SUPPORT: 'SUPPORT',
  SUPPORT_USER: 'SUPPORT_USER',
} as const;

export interface AddPermission {
  login: string;
  notify: boolean;
  permission: Permission;
}

export interface AddPermissionBody {
  permission: AddPermission;
}

export interface BulkAttachSettingsToCustomer {
  settingIds: string[];
}

export type CreateSettingBodyType = (typeof CreateSettingBodyType)[keyof typeof CreateSettingBodyType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateSettingBodyType = {
  web: 'web',
  app: 'app',
} as const;

export type CreateSettingBodyFirstLayerVariant =
  (typeof CreateSettingBodyFirstLayerVariant)[keyof typeof CreateSettingBodyFirstLayerVariant];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateSettingBodyFirstLayerVariant = {
  WALL: 'WALL',
  BANNER: 'BANNER',
} as const;

export type CreateSettingBodyButtonPrivacyVariant =
  (typeof CreateSettingBodyButtonPrivacyVariant)[keyof typeof CreateSettingBodyButtonPrivacyVariant];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateSettingBodyButtonPrivacyVariant = {
  fingerprint: 'fingerprint',
  settings: 'settings',
  security: 'security',
} as const;

export type LegalFramework = (typeof LegalFramework)[keyof typeof LegalFramework];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LegalFramework = {
  GDPR: 'GDPR',
  CCPA: 'CCPA',
  TCF2: 'TCF2',
  CPRA: 'CPRA',
  VCDPA: 'VCDPA',
  CPA: 'CPA',
  CTDPA: 'CTDPA',
  UCPA: 'UCPA',
} as const;

export interface CreateSettingBody {
  buttonBackgroundColor: string;
  buttonPrivacyVariant: CreateSettingBodyButtonPrivacyVariant;
  buttonTextColor: string;
  companyId: string;
  dataController: string;
  domains: string[];
  firstLayerVariant: CreateSettingBodyFirstLayerVariant;
  legalFramework: LegalFramework;
  name: string;
  type: CreateSettingBodyType;
}

export interface GetSettingsPermissionsBatchPublicInDto {
  login?: string;
  settingIds: string[];
}

export interface FixInconsistentInDto {
  login: string;
}

export type SettingModernity = (typeof SettingModernity)[keyof typeof SettingModernity];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SettingModernity = {
  modern: 'modern',
  legacy: 'legacy',
} as const;

export interface DefaultRule {
  noShow: boolean;
  settingsId: string;
}

export interface RuleDto {
  locations: string[];
  name: string;
  settingsId: string;
}

export interface RuleSetInDto {
  defaultRule: DefaultRule;
  description: string;
  name: string;
  rules: RuleDto[];
}

export interface VerifyTokenInDto {
  token: string;
}

export interface VerifyInDto {
  email: string;
}

export interface AuthenticatePartnerInDto {
  apiToken: string;
  user: string;
}

export interface AuthenticateInDto {
  authToken: string;
}
