import { Auth0Provider } from '@auth0/auth0-react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';

import AmplitudeProvider from 'lib/contexts/Amplitude';
import App from 'pages/App';

import { getAuthorizationParams } from './lib/helpers/getAuthorizationParams';
import ThemeProvider from './ThemeProvider';

import 'antd/dist/reset.css';
import './auth0.css';

console.log(
  `%cUsercentrics - Account Management - v${process.env.REACT_APP_VERSION}`,
  'color:#1d60b3;font-size:14px;font-weight:bold',
);

const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER || '';
TagManager.initialize({ gtmId });

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

// eslint-disable-next-line no-extend-native
String.prototype.toUpperCaseFirst = function (this: string) {
  return `${this.slice(0, 1).toUpperCase()}${this.toLowerCase().slice(1)}`;
};

root.render(
  <Auth0Provider
    domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
    clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
    authorizationParams={getAuthorizationParams()}
  >
    <ThemeProvider>
      <AmplitudeProvider>
        <Router basename={process.env.PUBLIC_URL}>
          <App />
        </Router>
      </AmplitudeProvider>
    </ThemeProvider>
  </Auth0Provider>,
);
