import { Col, Row, Divider, Select, Flex, Alert } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useGetMyAccounts, useAccountConsumption } from 'api/requests';
import { Permission } from 'api/requests/generated/generated.schemas';
import AccountAccess from 'components/AccountAccess';
import AccountDetails from 'components/AccountDetails';
import Card from 'components/Card';
import Collapse from 'components/Collapse';
import {
  NoCard as NoCardIcon,
  PlatformIndicator as PlatformIndicatorIcon,
  BankAccount as BankAccountIcon,
  Open as OpenIcon,
} from 'components/Icons';
import PageInfo from 'components/PageInfo';
import SubscriptionCard from 'components/SubscriptionCard';
import Usage from 'components/SubscriptionUsage/Usage';
import Tag, { NewTag } from 'components/Tag';
import * as Typography from 'components/Typography';
import { TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { trackEvent } from 'lib/helpers/amplitude';
import { Container } from 'shared-components';

import { SelectAccountContainer, IconBackground } from './styled';
import { renderPaymentSource } from './SubComponents';

const BillingAccount = () => {
  const { state } = useLocation();
  const theme = useTheme();
  const { data: fetchedAccounts, isLoading } = useGetMyAccounts();
  const [selectedAccount, setAccount] = useState(() => fetchedAccounts?.body?.[0] || null);
  const { data: consumptionData, isLoading: isConsumptionLoading } = useAccountConsumption(selectedAccount?.id || '', {
    periodicity: 'month',
    periods: 12,
  });
  const consumption = consumptionData?.body?.consumption || {};
  const showUpgradeLabel = Object.keys(consumption).some((sub) => consumption[sub].overuse.shouldNotifyAboutOveruse);

  useEffect(() => {
    if (state?.billingCustomerId) {
      const acc = fetchedAccounts?.body?.find((a) => a.id === state?.billingCustomerId);
      if (acc) {
        return setAccount(acc);
      }
    }

    setAccount(() => fetchedAccounts?.body?.[0] || null);
  }, [fetchedAccounts]);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const onChange = (id: string) => {
    const account = fetchedAccounts?.body?.find((acc) => acc.id === id);
    if (account) {
      setAccount(account);
    }
  };

  useEffect(() => {
    trackEvent(TrackingEvents.ACCOUNT_BILLING_PAGE_VISITED, {
      [TrackingVariables.BILLING_ACCOUNT_ID]: selectedAccount?.id,
    });
  }, []);

  if (isLoading || !selectedAccount) {
    return null;
  }

  const isReadOnly = selectedAccount.permission === Permission.READ;
  // TODO: This can be removed after 07-08-2024
  const showNewLabel = new Date().getTime() <= new Date('08-07-2024').getTime();

  return (
    <Container>
      <PageInfo
        title="Account & Billing"
        description="Manage the account and billing information for your Usercentrics accounts."
      />
      {fetchedAccounts && fetchedAccounts?.body.length > 1 && (
        <SelectAccountContainer>
          <BankAccountIcon />
          <Select
            variant="borderless"
            loading={isLoading}
            value={selectedAccount.id}
            onChange={(id) => onChange(id)}
            options={(fetchedAccounts?.body || []).map((a) => ({ label: a.name, value: a.id }))}
          />
        </SelectAccountContainer>
      )}
      <Card
        key={selectedAccount.id}
        data-testid={`ab:account-${selectedAccount.name}`}
        title={
          <Flex align="center" gap={12}>
            <IconBackground align="center" justify="center">
              <BankAccountIcon />
            </IconBackground>
            {selectedAccount.name}
          </Flex>
        }
        style={{ background: theme?.colors.gray[8], paddingTop: 24 }}
        styles={{
          header: { borderBottom: 'none' },
          body: {
            minWidth: 750,
          },
        }}
        bordered={false}
      >
        {showUpgradeLabel && (
          <Alert
            message={
              <>
                <Typography.Text strong>Upgrade required</Typography.Text> for your subscription(s).
              </>
            }
            type="warning"
            showIcon
            style={{ margin: '0 0 16px' }}
          />
        )}
        <Flex vertical gap={16}>
          <AccountDetails
            initialValues={{ ...selectedAccount, email: selectedAccount.user.email }}
            isReadOnly={isReadOnly}
          />
          <Collapse
            expandIconPosition="end"
            defaultActiveKey="subscription"
            items={[
              {
                key: 'subscription',
                id: 'subscription-row',
                label: (
                  <Flex justify="space-between">
                    <Typography.Text strong size="md">
                      Subscription
                    </Typography.Text>

                    {showNewLabel && <NewTag>New</NewTag>}
                  </Flex>
                ),
                children: (
                  <Card
                    bordered={false}
                    noResults={{
                      description:
                        'No subscription available. Valid subscriptions will be show automatically once available in Chargebee.',
                      icon: <PlatformIndicatorIcon />,
                      condition: !selectedAccount?.subscriptions.length,
                    }}
                  >
                    {selectedAccount?.subscriptions.map((subscription, index, arr) => (
                      <Row key={subscription.id} gutter={[24, 0]}>
                        <Col span={12}>
                          <SubscriptionCard
                            isBillingPage
                            subscription={subscription}
                            account={selectedAccount}
                            key={subscription.id}
                            isReadOnly={isReadOnly}
                          />
                        </Col>
                        <Col span={12}>
                          <Usage
                            subscription={subscription}
                            consumption={consumption[subscription.id]}
                            isLoading={isConsumptionLoading}
                            account={selectedAccount}
                            isReadOnly={isReadOnly}
                          />
                        </Col>
                        {index !== arr.length - 1 && (
                          <Divider style={{ width: 'calc(100% + 48px)', margin: '24px -24px' }} />
                        )}
                      </Row>
                    ))}
                  </Card>
                ),
              },
            ]}
          />

          <Collapse
            expandIconPosition="end"
            defaultActiveKey="payment"
            items={[
              {
                key: 'payment',
                label: (
                  <Typography.Text strong size="md">
                    Payment
                  </Typography.Text>
                ),
                children: (
                  <Card
                    bordered={false}
                    noResults={{
                      description: 'No payment method added',
                      icon: <NoCardIcon />,
                      condition: !selectedAccount.paymentSources.length,
                    }}
                    footer={
                      <>
                        If you have any question about payment or invoices please mail us at {''}
                        <Typography.Link size="sm" href="mailto:invoices@usercentrics.com">
                          invoices@usercentrics.com
                        </Typography.Link>
                      </>
                    }
                  >
                    <Row gutter={[0, 8]}>{selectedAccount.paymentSources.map(renderPaymentSource)}</Row>
                  </Card>
                ),
              },
            ]}
          />
          {Boolean(selectedAccount.companies.length) && (
            <Collapse
              expandIconPosition="end"
              defaultActiveKey="companies"
              items={[
                {
                  key: 'companies',
                  label: (
                    <Typography.Text strong size="md">
                      Companies
                    </Typography.Text>
                  ),
                  children: (
                    <Card bordered={false}>
                      {selectedAccount.companies.map((company) => (
                        <Tag $bold={false} size="middle" key={company.id}>
                          <Link to={`/company/${company.id}/configurations`}>{company.name}</Link>
                          <OpenIcon />
                        </Tag>
                      ))}
                    </Card>
                  ),
                },
              ]}
            />
          )}

          <AccountAccess account={selectedAccount} />
        </Flex>
      </Card>
    </Container>
  );
};

export default BillingAccount;
