import { Flex, Skeleton } from 'antd';
import { Col, Divider } from 'antd/lib';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  AccountOutDto,
  SubscriptionDto,
  SubscriptionType as ISubscriptionType,
  SubscriptionConsumptionDto,
  SubscriptionPricePlan,
} from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import Tooltip from 'components/Form/Tooltip';
import { Success } from 'components/Icons';
import Progress from 'components/Progress/Progress';
import { Text, Title } from 'components/Typography';
import { isOnePricingSub } from 'lib/contexts/Entitlement';
import { IUpgradeSubscription } from 'lib/contexts/UpgradePlan';
import { parseUsageDate } from 'lib/helpers';
import { getAverageConsumption } from 'lib/helpers/overusage';
import { useUpgradePlan } from 'lib/hooks';

import Row from './Row';
import { Container, UpgradeMessage, SessionsContainer, Disclaimer, SuccessMessage } from './styled';

interface IUsageProps {
  subscription: SubscriptionDto;
  account: AccountOutDto;
  isLoading: boolean;
  consumption: SubscriptionConsumptionDto;
  isReadOnly?: boolean;
}

const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
const currentYear = new Date().getFullYear();

const isOldOrBusinessSub = (subscription: SubscriptionDto) =>
  !isOnePricingSub(subscription) || subscription.pricePlan === SubscriptionPricePlan.BusinessExtended;

const Usage = ({ subscription, consumption, isLoading, account, isReadOnly }: IUsageProps) => {
  const location = useLocation();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [expendedTable, setExpendedTable] = useState(false);
  const primaryPaymentMethod = account.paymentSources.find((ps) => ps.id === account.primaryPaymentSourceId);
  const { setPlanToUpgrade } = useUpgradePlan();

  useEffect(() => {
    if (location?.state?.subscriptionUpgraded === subscription.id) {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        location.state.subscriptionUpgraded = null;
      }, 5000);
    }
  }, [location.state]);

  const currentMonthFormatted = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
  const value = consumption?.breakdown?.[currentMonthFormatted]?.total || 0;
  const max = consumption?.metrics?.estimatedConsumption || subscription.quantity;

  // Only in the last exceeded period
  const averageUsage = getAverageConsumption(consumption);

  const breakdown = Object.keys(consumption?.breakdown || {});

  return (
    <Container>
      <Title level={4} style={{ marginBottom: '16px' }} weight={500}>
        Usage
      </Title>
      {consumption &&
        consumption.overuse.shouldNotifyAboutOveruse &&
        subscription.status === 'active' &&
        isOldOrBusinessSub(subscription) && (
          <UpgradeMessage
            entitlementNotRequired
            description={
              <>
                Your usage exceeded your limit for{' '}
                <Text strong>{consumption.overuse.details.metrics.currentSeries?.length} consecutive months</Text>.
                Please upgrade your subscription.
              </>
            }
            subscriptionId={subscription.id}
            buttonText="upgrade"
            buttonSize="small"
            showButton={Boolean(!isReadOnly && primaryPaymentMethod && consumption.recommendedTier)}
            onClick={() =>
              setPlanToUpgrade(subscription as unknown as IUpgradeSubscription, {
                recommendedTier: consumption.recommendedTier?.maxValue || consumption.recommendedTier?.minValue || 0,
                amountOfMonths: consumption.overuse.details.metrics.currentSeries?.length || 0,
                averageUsage,
              })
            }
          />
        )}
      <Flex justify="space-between">
        <Flex align="center">
          <Text style={{ marginRight: '4px' }}>
            {subscription.type === ISubscriptionType.web ? 'Sessions' : 'Daily Active Users (DAU)'}
          </Text>
          <Tooltip
            placement="top"
            title={
              subscription.type === ISubscriptionType.web
                ? 'A session lasts 30 minutes. Each refresh by a user after 30 minutes is counted as a new session.'
                : 'We define a Daily Active Users as a unique user who used an app within a given calendar day.'
            }
          />
        </Flex>
        <Text>
          {currentMonth}/{currentYear}
        </Text>
      </Flex>
      {showSuccessMessage && (
        <SuccessMessage align="center">
          <Success />
          <Text style={{ marginLeft: '12px' }}>Upgrade successful.</Text>
        </SuccessMessage>
      )}
      {isLoading ? (
        <Flex vertical>
          <Skeleton.Button active style={{ width: '100%', height: '106px', margin: '8px 0 16px 0' }} />
        </Flex>
      ) : (
        <SessionsContainer justify="center" align="center" $completed={value > max}>
          <Progress min={0} max={max} value={value} />
        </SessionsContainer>
      )}
      <Flex gap={8} style={{ marginBottom: '4px' }}>
        <Col span={8} style={{ paddingLeft: '12px' }}>
          <Text size="sm" weight={500}>
            Month
          </Text>
        </Col>
        <Col span={8}>
          <Text size="sm" weight={500}>
            Consumed
          </Text>
        </Col>
        <Col span={8}>
          <Text size="sm" weight={500}>
            Exceeded
          </Text>
        </Col>
      </Flex>
      {isLoading ? (
        <Flex vertical gap={2}>
          <Skeleton.Button active size="large" style={{ width: '100%' }} />
          <Skeleton.Button active size="large" style={{ width: '100%' }} />
          <Skeleton.Button active size="large" style={{ width: '100%' }} />
        </Flex>
      ) : (
        consumption && (
          <>
            {breakdown.length <= 1 && <Row date={new Date()} amount={0} isExceeded={false} max={0} notAvailable />}
            {breakdown.slice(1, expendedTable ? undefined : 4).map((period) => (
              <Row
                key={period}
                date={parseUsageDate(period)}
                amount={consumption.breakdown[period].total || 0}
                isExceeded={consumption.overuse.details.metrics.overusePeriods.includes(period)}
                max={max}
                notAvailable={!consumption.breakdown[period]}
              />
            ))}
            {!expendedTable && breakdown.length > 4 && (
              <Flex justify="center">
                <Button type="link" onClick={() => setExpendedTable(true)} style={{ fontWeight: '600' }}>
                  Show more
                </Button>
              </Flex>
            )}
          </>
        )
      )}
      <Divider style={{ marginTop: '16px', marginBottom: '8px' }} />
      <Disclaimer size="sm">
        We aggregate your data from all configurations that are assigned to this subscription.
      </Disclaimer>
    </Container>
  );
};

export default Usage;
