import { Col, Divider, Flex, Form } from 'antd';
import { useEffect, useRef, useState } from 'react';

import { estimateUpgradeNew, updateBillingAccountBillingInfo } from 'api/requests';
import { EstimationOutDto, SubscriptionPricePlan } from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import TermsAndConditions from 'components/TermsAndConditions';
import { Text } from 'components/Typography';
import { useManageSubscription, useMessage } from 'lib/hooks';
import Support from 'pages/ManageSubscription/components/Support';

import BillingInformationForm, { IBillingInformationForm } from './BillingInformationForm';
import PaymentInformationForm, { RefType } from './PaymentInformationForm';
import PlanOverview from './PlanOverview';
import { Container } from './styled';
import { Sider, Content, Footer, ContentLayout } from '../../styled';

type Forms = 'paymentDetails' | 'billingDetails' | 'termsAndConditions';

const Payment = () => {
  const { back, forward, account, subscription, selectedPricePlan } = useManageSubscription();
  const [billingForm] = Form.useForm<IBillingInformationForm>();
  const [nameForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [estimation, setEstimation] = useState<EstimationOutDto | null>(null);
  const paymentInfoRef = useRef<RefType>(null);
  const message = useMessage();

  const [formsValidity, setFormsValidity] = useState<{ [key in Forms]: boolean }>({
    paymentDetails: false,
    billingDetails: false,
    termsAndConditions: false,
  });

  if (!account || !subscription || !selectedPricePlan) {
    back();
    return null;
  }

  useEffect(() => {
    (async () => {
      await estimate();
    })();
  }, []);

  const estimate = async () => {
    const fetchedEstimation = await estimateUpgradeNew(subscription.id, {
      pricePlan: selectedPricePlan.pricePlan as SubscriptionPricePlan,
      quantity: subscription.maxValue,
    });

    if (fetchedEstimation.body) {
      setEstimation(fetchedEstimation.body);
    }

    return fetchedEstimation.body;
  };

  const updateFormsValidity = (key: Forms, isValid: boolean) => {
    setFormsValidity((prev) => ({ ...prev, [key]: isValid }));
  };

  const updateBillingDetails = async () => {
    try {
      setIsLoading(true);
      const { name, address } = billingForm.getFieldsValue();

      await updateBillingAccountBillingInfo(account?.id, { name, ...address });
    } catch (error) {
      updateFormsValidity('billingDetails', false);
    } finally {
      setIsLoading(false);
    }
  };

  const onBuyNowClick = async () => {
    try {
      await updateBillingDetails();
      const est = await estimate();

      if (est) {
        setIsLoading(true);

        await paymentInfoRef.current?.onConfirmPayment({
          subscription,
          pricePlan: selectedPricePlan.pricePlan as SubscriptionPricePlan,
          accountId: account.id,
          amount: est.perBillingCycle.total,
        });

        setIsLoading(false);
      }

      forward();
    } catch (error) {
      message.error(error?.message);
    }
  };

  return (
    <>
      <Sider width={350}>
        <Flex vertical gap={16}>
          <Text size="md" strong>
            Order summary
          </Text>
          <PlanOverview estimation={estimation?.perMonth.taxableAmount || selectedPricePlan.tier.monthlyPrice} />
          <Button style={{ fontWeight: 500, textDecoration: 'underline' }} type="link" onClick={back}>
            Change Plan
          </Button>
        </Flex>
        <Support />
      </Sider>
      <ContentLayout>
        <Content style={{ justifyContent: 'space-between' }}>
          <Container justify="space-between" gap={40}>
            <Col>
              <BillingInformationForm
                form={billingForm}
                onValuesChange={(isValid) => updateFormsValidity('billingDetails', isValid)}
                estimate={estimate}
              />
            </Col>
            <Divider style={{ height: '100%', margin: 0 }} type="vertical" />
            <Col>
              <PaymentInformationForm
                ref={paymentInfoRef}
                nameForm={nameForm}
                onFormValidation={(isValid) => updateFormsValidity('paymentDetails', !isValid)}
              />
            </Col>
          </Container>

          <Footer>
            <TermsAndConditions setIsCheckboxValid={(isValid) => updateFormsValidity('termsAndConditions', isValid)} />
            <Button
              loading={isLoading}
              onClick={onBuyNowClick}
              disabled={!Object.values(formsValidity).every(Boolean)}
              size="large"
              type="primary"
            >
              Buy now
            </Button>
          </Footer>
        </Content>
      </ContentLayout>
    </>
  );
};

export default Payment;
