import { Flex, Form, List, Skeleton, Space, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import Button from 'components/Button';
import Card from 'components/Card';
import { Input, Item } from 'components/Form';
import Tag from 'components/Tag';
import { Title } from 'components/Typography';
import { QuotaNames } from 'interfaces/enums';
import { domainRegexp } from 'lib/helpers/validation';
import { useEntitlement } from 'lib/hooks';
import { colors } from 'lib/theme/colors';

import { AddDomainListItem } from './AddDomainListItem';
import { ScrollContainer } from './styled';

const { Paragraph } = Typography;

interface Props {
  onDomainsChange: (domains: string[]) => void;
  domains: string[];
}

export const AddDomain = ({ onDomainsChange, domains }: Props) => {
  const { subscription, getQuotaPerSubscription } = useEntitlement();

  const [addedDomains, setAddedDomains] = useState<string[]>(domains);
  const [displayDomains, setDisplayDomains] = useState<string[]>([]);
  const [domainsLimit, setDomainsLimit] = useState(0);
  const [domainsInUse, setDomainsInUse] = useState(0);
  const [hasErrors, setHasErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [domainForm] = Form.useForm();
  const values = Form.useWatch([], domainForm);

  useEffect(() => {
    (async () => {
      if (subscription) {
        setIsLoading(true);
        const quotaResult = await getQuotaPerSubscription([QuotaNames.UNIQUE_DOMAINS]);
        if (quotaResult) {
          const { inUse, limit, uniqueDomains } = quotaResult[QuotaNames.UNIQUE_DOMAINS] || {};
          setDomainsInUse(inUse || 0);
          setDomainsLimit(limit || 0);
          setDisplayDomains(uniqueDomains || []);
        }
        setIsLoading(false);
      }
    })();
  }, [subscription]);

  useEffect(() => {
    domainForm
      .validateFields({ validateOnly: true })
      .then(() => setHasErrors(false))
      .catch(() => setHasErrors(true));
  }, [domainForm, values]);

  const changeAddedDomains = (domain: string) => {
    const newValue = [...addedDomains, domain];
    setAddedDomains(newValue);
    onDomainsChange(newValue);
  };

  const onAddDomainFromInput = () => {
    const domainValue = domainForm.getFieldValue('domain')?.trim()?.toLowerCase();

    changeAddedDomains(domainValue);
    setDisplayDomains([...displayDomains, domainValue]);

    domainForm.resetFields();
  };

  const onAddDomainFromSubscription = useCallback(
    (domain: string) => {
      changeAddedDomains(domain);
    },
    [changeAddedDomains],
  );

  const isAddedDomain = (domain: string): boolean => {
    return addedDomains.some((addedDomain) => addedDomain.toLowerCase() === domain.toLowerCase());
  };

  return (
    <>
      <Title level={3}>Add Domain</Title>
      <Paragraph>
        Select the legal framework your configuration should support. Depending on your choice, there will be a default
        setup prepared including all required features to be compliant with the selected framework.
      </Paragraph>
      <Flex vertical gap={24}>
        {!(domainsLimit <= domainsInUse) && (
          <Card bordered={false} style={{ backgroundColor: colors.gray[8] }}>
            <Form name="domainForm" form={domainForm} onFinish={onAddDomainFromInput}>
              <Flex align="center" gap={8}>
                <Item
                  name="domain"
                  rules={[
                    { pattern: domainRegexp, message: 'Please enter a valid domain string' },
                    {
                      validator: (_, value: string) => {
                        if (addedDomains.includes(value?.trim()?.toLowerCase())) {
                          return Promise.reject(new Error('Please use only unique domains'));
                        }
                        return Promise.resolve();
                      },
                    },
                    { required: true, message: 'Input required' },
                  ]}
                  requiredMark={false}
                  colon={false}
                  label="Domain"
                  style={{ flex: 1 }}
                >
                  <Input
                    status={hasErrors && domainForm.isFieldTouched('domain') ? 'error' : ''}
                    placeholder="wwww.example.com"
                    size="large"
                    addonBefore="https://"
                  />
                </Item>
                <Item style={{ alignSelf: 'flex-end' }}>
                  <Button size="large" type="primary" htmlType="submit" disabled={hasErrors}>
                    Add
                  </Button>
                </Item>
              </Flex>
            </Form>
          </Card>
        )}

        {isLoading ? (
          <Skeleton active />
        ) : (
          <List
            size="large"
            header={
              <Title level={4}>
                <Space>
                  Domain Overview
                  <Tag $rounded bordered color="blue-inverse">
                    {domainsInUse}/{domainsLimit}
                  </Tag>
                </Space>
              </Title>
            }
            bordered
          >
            <ScrollContainer>
              {displayDomains.map((item, index) => (
                <AddDomainListItem
                  key={item + index}
                  domain={item}
                  isAdded={isAddedDomain(item)}
                  onAdd={onAddDomainFromSubscription}
                />
              ))}
            </ScrollContainer>
          </List>
        )}
      </Flex>
    </>
  );
};
