import { useAuth0 } from '@auth0/auth0-react';

import { OnePricingPricePlans } from 'interfaces/enums';
import { useEntitlement } from 'lib/hooks';

import { ITagProps, PremiumTag } from '..';

const PremiumTagIfOnePricing = (props: ITagProps) => {
  const { subscription } = useEntitlement();
  if (subscription && !OnePricingPricePlans.includes(subscription.plan)) {
    return null;
  }

  const { user } = useAuth0();
  const isOnePricing = Boolean(user?.one_pricing_plan);
  if (!subscription && !isOnePricing) {
    return null;
  }

  return <PremiumTag {...props} />;
};

export { PremiumTagIfOnePricing };
