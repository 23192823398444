import { useAuth0 } from '@auth0/auth0-react';
import { Flex } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import Button from 'components/Button';
import { Text } from 'components/Typography';
import { OnePricingPricePlans, TrackingEvents } from 'interfaces/enums';
import { trackEvent } from 'lib/helpers/amplitude';
import { useEntitlement, useManageSubscription } from 'lib/hooks';

import { Container, Tag } from './styled';

interface IProps {
  title?: string;
  description: string | React.ReactNode;
  buttonText: 'upgrade' | 'full-access';
  subscriptionId: string;
  style?: object;
  showButton?: boolean;
  onClick?: () => void;
  buttonSize?: SizeType;
  entitlementNotRequired?: boolean;
}

const PremiumFeatureBar = ({
  entitlementNotRequired,
  title,
  description,
  subscriptionId,
  buttonText,
  showButton = true,
  onClick,
  buttonSize,
  ...props
}: IProps) => {
  const { open } = useManageSubscription();
  const { subscription } = useEntitlement();
  const { user } = useAuth0();

  if (!entitlementNotRequired && subscription && !OnePricingPricePlans.includes(subscription.plan)) return <></>;

  const isOnePricing = Boolean(user?.one_pricing_plan);
  if (!entitlementNotRequired && !subscription && !isOnePricing) return <></>;

  const onUpgradeClick = () => {
    trackEvent(TrackingEvents.CTA_QUOTA_BAR_UPGRADE);
    open(subscriptionId);
  };

  return (
    <Container justify="space-between" {...props}>
      <Flex align="center" gap={8}>
        <Tag size="square-large" color="orange-noshadow" />
        <Flex vertical>
          {title && <Text weight={500}>{title}</Text>}
          <Text type="secondary">{description}</Text>
        </Flex>
      </Flex>
      {showButton && (
        <Button
          type="primary"
          size={buttonSize || 'large'}
          style={{ paddingLeft: '16px', paddingRight: '16px', marginLeft: '12px' }}
          onClick={onClick || onUpgradeClick}
        >
          {buttonText === 'upgrade' ? 'Upgrade' : 'Get Full Access'}
        </Button>
      )}
    </Container>
  );
};

export default PremiumFeatureBar;
